import React from 'react';
import { useTranslate } from '_core/hooks/useTranslate';

import Button from '_core/modules/atoms/Button';
import LineWithText from '_core/modules/atoms/LineWithText';
import Tag from '_core/modules/atoms/Tag';

const WelcomeStep = ({ userContext, onLoginMicrosoft, setStep, setMode, onConsentRequest, shouldConsent, isLoading }) => {
  const { t } = useTranslate();

  return (
    <div className="welcome-step__container">
      <div className="welcome-step__welcome-text">Hola {userContext?.user?.displayName || userContext?.user?.userPrincipalName}!</div>
      <div style={{ marginTop: 16 }}>
        <div style={{ marginTop: 8 }}>
          {t('login:Your are adding Edelvives Digital Plus to')} <Tag text={`${userContext?.team?.displayName} - ${userContext?.channel?.displayName}`} />
        </div>
        <div style={{ marginTop: 48 }}>
          {!shouldConsent && (
            <>
              <Button
                testid="btn-sso-microsoft"
                color="white"
                text={`${t('login:Log in')} ${t('login:with')} ${t('login:Login with Microsoft')}`}
                icon="microsoft"
                iconPosition="left"
                loading={isLoading}
                onClick={onLoginMicrosoft}
              />
              <div style={{ marginTop: 16, marginBottom: 16 }}>
                <LineWithText text="O" />
              </div>
              <Button
                text={`${t('login:If you don’t have an account yet,')} ${t('login:register here')}`}
                color="white"
                onClick={() => {
                  setStep(2);
                  setMode('register');
                }}
              />
            </>
          )}

          {shouldConsent && (
            <Button
              text={`Por favor, haga clic aquí para aceptar el uso de Edelvives digital plus antes de iniciar sesión`}
              color="white"
              onClick={onConsentRequest}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WelcomeStep;
