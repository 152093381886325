import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import BasicList from '_core/modules/components/lists/components/BasicList';
import LicenseItem from '../LicenseItem';

const LicensesList = ({ licenses, onClickView, loadMore, hasMore, onDelete, typeView, configProgramPicture }) => {
  return (
    <div className={`licenses-list`}>
      <InfiniteScroll pageStart={0} loadMore={loadMore} hasMore={hasMore}>
        <BasicList
          items={licenses.map((item) => (
            <LicenseItem
              {...item}
              configProgramPicture={configProgramPicture}
              onClickView={() => onClickView(item)}
              onDelete={onDelete ? () => onDelete(item) : null}
              typeView={typeView}
            />
          ))}
        />
      </InfiniteScroll>
    </div>
  );
};

LicensesList.propTypes = {
  licenses: PropTypes.array,
  onClickView: PropTypes.func,
  typeView: PropTypes.oneOf(['default', 'simple']),
};

LicensesList.defaultProps = {
  licenses: [],
  onClickView: function () {},
  typeView: 'default',
};

export default LicensesList;
