import * as coreEntities from '_core/store/index';
import { BANNER_FORM_IMAGE } from '_core/variables/constant';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../modules/atoms/Button';
import Icon from '../modules/atoms/Icon';
import Image from '../modules/atoms/Image';
import ImageGradient from '../modules/atoms/ImageGradient';
import ProgressBar from '../modules/atoms/ProgressBar';
import TextLink from '../modules/atoms/TextLink';

const initialStep = 0;
function LayoutProgress({
  children,
  iconTypeRight,
  onClickIconTypeRight,
  t,
  onEnd,
  onlyStep,
  header,
  backgroundColor,
  showProgress,
  onNext,
  onBack,
  disabledButtonParent,
  parentStep,
  setParentStep,
  parentProgress,
  setParentProgress,
  colorIconClose,
  isSkippable,
  returnInTheFirstStep,
  showLogo,
  showStepsInCreate,
}) {
  const tenant = useSelector((state) => coreEntities.organization.selectors.getTenant(state));

  const steps = children.length ? children : [children];
  const [progress, setProgress] = useState((100 * (initialStep + 1)) / steps.length);
  const [disabledButton, setDisabledButton] = useState(true);
  const [stepActive, setStepActive] = useState(onlyStep ? onlyStep : initialStep);
  const [textButtonNext, setTextButtonNext] = useState(t('Continue'));
  const [showBackButton, setShowBackButton] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const [end, setEnd] = useState(false);

  const layoutImage = BANNER_FORM_IMAGE[tenant?.guid] || null;
  const _step = parentStep !== undefined ? parentStep : stepActive;
  const _setStep = setParentStep !== undefined ? setParentStep : setStepActive;
  const _progress = parentProgress !== undefined ? parentProgress : progress;
  const _setProgress = setParentStep !== undefined ? setParentProgress : setProgress;
  const _disabledButton = disabledButtonParent === false || disabledButtonParent === true ? disabledButtonParent : disabledButton;

  function onNextStep() {
    onNext(_step + 1);
    if (onlyStep || onlyStep === 0) {
      setEnd(true);
      onEnd();
    } else {
      let next = _step + 1;
      if (next !== steps.length) {
        _setStep(next);
        changeProgress(next);
      } else {
        setEnd(true);
        onEnd();
      }
    }
  }

  function onBackStep() {
    onBack();

    let last = _step - 1;
    _setStep(last);
    changeProgress(last);
  }

  function changeProgress(step) {
    _setProgress((100 * (step + 1)) / steps.length);
  }

  function changeDisabledButton(disabled) {
    setDisabledButton(disabled);
  }

  function changeTextButton(text) {
    setTextButtonNext(text);
  }

  function changeShowBackButton(visible) {
    setShowBackButton(visible);
  }

  function changeShowFooter(visible) {
    setShowFooter(visible);
  }

  function getStep(child) {
    return React.cloneElement(child, {
      changeProgress: changeProgress,
      stepActive: _step,
      changeDisabledButton: changeDisabledButton,
      changeTextButton: changeTextButton,
      changeShowBackButton: changeShowBackButton,
      changeShowFooter: changeShowFooter,
      end: end,
    });
  }

  return (
    <div className={`layout-progress layout-progress--background-${backgroundColor}`}>
      {iconTypeRight && onClickIconTypeRight && (
        <div className="layout-progress__icon-right" onClick={onClickIconTypeRight}>
          {showStepsInCreate && !onlyStep && onlyStep !== 0 && (
            <div className="layout-progress__steps">{t('common:Step X of Y', { steps: steps.length, current: _step + 1 })}</div>
          )}
          <Icon type={iconTypeRight} size="big" color={colorIconClose} />
        </div>
      )}
      {!header && (
        <div className="layout-progress__header">
          {showLogo && (
            <div className="layout-progress__header-logo">
              <Image id={'logoWhite'} />
            </div>
          )}
          <div className="layout-progress__image">{<ImageGradient image={layoutImage ? layoutImage : null} noFilter={layoutImage ? true : false} />}</div>
        </div>
      )}
      {header ? header : null}
      <div className="layout-progress__children">
        <div className="layout-progress__children-container">{getStep(steps[_step])}</div>
      </div>
      {showProgress && (
        <div className="layout-progress__bar-progress">
          <ProgressBar size="medium" withLabel={false} progress={_progress} />
        </div>
      )}
      {showFooter && (
        <div className="layout-progress__footer-container">
          <div className="layout-progress__footer">
            <div className="layout-progress__footer__button">
              {_step > 0 && !onlyStep && showBackButton && <Button text={t('Back')} variant="inline" color="transparent" onClick={onBackStep} />}
              {_step === 0 && returnInTheFirstStep && <Button text={t('Back')} variant="inline" color="transparent" onClick={returnInTheFirstStep} />}
            </div>
            <div className="layout-progress__footer__button layout-progress__footer__button--inline">
              {isSkippable && (
                <div className="layout-progress__footer__skip-button">
                  <TextLink text={t('actions:Skip')} onClick={onNextStep} />
                </div>
              )}
              <Button variant="inline" text={textButtonNext} size="small" onClick={onNextStep} disabled={_disabledButton} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

LayoutProgress.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  backgroundColor: PropTypes.oneOf(['white', 'default']),
  iconTypeRight: PropTypes.string,
  onClickIconTypeRight: PropTypes.func,
  totalSteps: PropTypes.number,
  showProgress: PropTypes.bool,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  colorIconClose: PropTypes.oneOf(['white', 'default']),
  isSkippable: PropTypes.bool,
};

LayoutProgress.defaultProps = {
  backgroundColor: 'default',
  onClickIconTypeRight: function () {
    return null;
  },
  onNext: function () {
    return null;
  },
  onBack: function () {
    return null;
  },
  colorIconClose: 'default',
  showProgress: false,
  isSkippable: false,
  showFooter: true,
};

export default withTranslation(['actions', 'common'])(LayoutProgress);
