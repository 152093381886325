import LandingEdelvivesPlus from 'app/modules/compositions/LandingEdelvivesPlus/LandingEdelvivesPlus';
import React from 'react';

const SupportView = () => {
  return (
    <div className="about-view support-view">
      <div className="about-view__wrapper">
        <LandingEdelvivesPlus />
        <div className="about-view__contact">Para cualquier soporte que necesites por favor contacta con nosotros en info@edelvives.es</div>
      </div>
    </div>
  );
};

export default SupportView;
