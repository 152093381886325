import PropTypes from 'prop-types';
import React from 'react';
import Icon from '_core/modules/atoms/Icon';
import IconBubble from '_core/modules/atoms/IconBubble';
import ItemComplexList from '_core/modules/components/general/ItemComplexList';

const ClassroomsSimpleItem = ({ code, name, level, onDelete }) => {
  function renderInfo() {
    return (
      <div className="classrooms-simple-item__info">
        <div className="dropdown-courses__bubbles">
          <IconBubble size="big" typeIcon="presentation" />
        </div>
        <div className="classrooms-simple-item__info-main">
          <span className="classrooms-simple-item__name" title={`${name}`}>
            {name}
          </span>
          <span className="classrooms-simple-item__level">{level}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="classrooms-simple-item">
      <ItemComplexList
        center={renderInfo()}
        right={
          <div className="classrooms-simple-item__options" onClick={() => onDelete(code)}>
            <Icon type="trash" size="" />
          </div>
        }
      />
    </div>
  );
};

ClassroomsSimpleItem.propTypes = {
  name: PropTypes.object,
  level: PropTypes.string,
};

ClassroomsSimpleItem.defaultProps = {
  onDelete: function () {},
};

export default ClassroomsSimpleItem;
