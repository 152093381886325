import { GoogleLogin } from '@react-oauth/google';
import { ValidateEmail, i18next, successResponse } from '_core';
import { checkUser } from '_core/crud/auth.crud';
import Button from '_core/modules/atoms/Button';
import LineWithText from '_core/modules/atoms/LineWithText';
import Logo from '_core/modules/atoms/Logo/Logo';
import TextHtml from '_core/modules/atoms/TextHtml';
import DialogDefault from '_core/modules/components/dialogs/components/Dialog';
import AccountForm from '_core/modules/components/forms/AccountForm';
import { getTokenUserDetails } from '_core/utils/utils';
import clsx from 'clsx';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

const SignUpStepCreateAccount = ({
  t,
  onChangeDisabledButton,
  changeUser,
  user,
  changeErrorForm,
  errorForm,
  onSuccessGoogle,
  onFailureGoogle,
  onLoginMicrosoft,
  layoutRight,
  terms,
  step,
}) => {
  // ejemplo de error
  //{password: t('common:Required parameter'),}
  //const [errorForm, setErrorForm] = useState({});
  const [existUsername, setExistUsername] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const [debounceCheckUsername] = useState(() =>
    debounce((email, name, latestErrorForm, newUser) => {
      if (ValidateEmail(email)) {
        checkIfUserExists(email, name, latestErrorForm, newUser);
      } else {
        checkIfEmailIsValid(email, name, latestErrorForm, newUser);
      }
    }, 1000)
  );

  const [debounceCheckEmailMatch] = useState(() =>
    debounce((email, name, latestErrorForm, newUser) => {
      checkIfEmailMatch(email, name, latestErrorForm, newUser);
    }, 1000)
  );

  useEffect(() => {
    onChangeDisabledButton(true);
  }, []);

  useEffect(() => {
    onChangeDisabledButton(!validateUser());
  }, [user, existUsername]);

  useEffect(() => {
    if (user.identity_provider) debounceCheckUsername(user.email, errorForm);
  }, [user.identity_provider, user.email]);

  function onChangeErrorForm(name, value, newUser) {
    let tempErrors = errorForm;

    if ((name === 'password' && value.length < 3) || (name === 'confirmPassword' && value.length < 3)) {
      changeErrorForm({ ...tempErrors, [name]: t('signup:Password is too short') });
    } else if ((name === 'password' && newUser.confirmPassword) || (name === 'confirmPassword' && newUser.password)) {
      if (newUser.confirmPassword !== value || newUser.password !== value) {
        changeErrorForm({ ...tempErrors, [name]: t('signup:The password does not match') });
      } else {
        changeErrorForm({ ...tempErrors, password: null, confirmPassword: null });
      }
    }

    if (name === 'email' && value.length > 2) {
      debounceCheckUsername(value, name, tempErrors, newUser);
    }

    if (name === 'confirmEmail' && value.length > 2 && newUser?.email?.length > 2) {
      debounceCheckEmailMatch(value, name, tempErrors, newUser);
    }
  }

  function validateUser() {
    return (
      (!user.identity_provider &&
        user.name &&
        user.name.trim().length > 1 &&
        user.surname &&
        user.surname.trim().length > 1 &&
        user.password &&
        user.password.trim().length >= 3 &&
        user.password === user.confirmPassword &&
        ValidateEmail(user.email) &&
        user.email === user.confirmEmail &&
        user.terms &&
        !existUsername) ||
      (user.identity_provider && user.name && user.surname && user.token && user.token && user.terms && !existUsername)
    );
  }

  function checkIfEmailIsValid(email, name, latestErrorForm, newUser) {
    if (email && email.length > 2 && !ValidateEmail(email)) {
      changeErrorForm({ ...latestErrorForm, [name]: t('login:Invalid email') });
    } else {
      checkIfEmailMatch(email, name, latestErrorForm, newUser);
    }
  }

  function checkIfEmailMatch(email, name, latestErrorForm, newUser) {
    if ((name === 'email' && newUser.confirmEmail && email !== newUser.confirmEmail) || (name === 'confirmEmail' && newUser.email && email !== newUser.email)) {
      changeErrorForm({ ...latestErrorForm, [name]: t('signup:The email does not match') });
    } else {
      changeErrorForm({ ...latestErrorForm, email: null, confirmEmail: null });
    }
  }

  async function checkIfUserExists(email, name, latestErrorForm, newUser) {
    const response = await checkUser({ users: [{ username: email }] });
    if (successResponse(response)) {
      const data = response.data;
      if (data && data.data.length > 0 && data.data[0].username_check) {
        if (data.data[0].username_check.code === 409) {
          setExistUsername(true);
          changeErrorForm({ ...latestErrorForm, [name]: t('signup:User already exists') });
        } else if (data.data[0].username_check.code === 200) {
          setExistUsername(false);
          changeErrorForm({ ...latestErrorForm, [name]: null });
          checkIfEmailIsValid(email, name, latestErrorForm, newUser);
        }
      }
    } else {
      setExistUsername(false);
    }
  }

  function onChange(e) {
    let name = e.target.name;
    const value = e.target.value;
    changeUser({ ...user, [name]: value });
    changeErrorForm({ ...errorForm, [name]: null });
    onChangeErrorForm(name, value, { ...user, [name]: value });
  }

  function onAcceptTerms(e) {
    changeUser({ ...user, terms: e.target.checked });
  }

  function onOpenTerms(e) {
    e.preventDefault();
    if (terms) setShowTerms(true);
  }

  return (
    <div className="signup-step-license signup-step-license--create-account">
      <div className="signup__step">
        <div className="signup__column1">
          <div className="signup__step-content">
            <div className="signup__logo">
              <Logo size="big" />
            </div>
            <div className="signup__header">
              <span className="signup__titlepart1">
                {t('Create')}&nbsp;<span className="signup__titlepart2">{t('Account')}</span>{' '}
              </span>
            </div>
            <div className="signup__content">
              <div className="signup__content-third-party">
                <div className="signup__button-google signup__item">
                  <GoogleLogin
                    text="signup_with"
                    onSuccess={(credentialResponse) => {
                      onSuccessGoogle(getTokenUserDetails(credentialResponse.credential));
                    }}
                    onError={onFailureGoogle}
                    locale={i18next.language}
                  />
                </div>
                <div className="signup__button-ms signup__item">
                  <Button
                    testid="btn-sso-microsoft"
                    color="white"
                    text={t('login:Login with Microsoft')}
                    icon="microsoft"
                    iconPosition="left"
                    onClick={onLoginMicrosoft}
                  />
                </div>
              </div>
              <div className="signup__item">
                <LineWithText text={t('Or')} />
              </div>

              <AccountForm onChange={onChange} error={errorForm} onAcceptTerms={onAcceptTerms} onOpenTerms={onOpenTerms} user={user} />
            </div>
          </div>
        </div>

        <div className="signup__column2">{layoutRight || <div className={clsx('signup__image', `signup__image--step${step}`)}></div>}</div>
      </div>
      <DialogDefault className="dialog-terms" title={t('signup:terms and conditions').toUpperCase()} open={showTerms} onClose={() => setShowTerms(false)}>
        <TextHtml text={terms} />
      </DialogDefault>
    </div>
  );
};

SignUpStepCreateAccount.defaultProps = {
  onLoginMicrosoft: () => {},
  step: 2,
};

export default withTranslation(['signup', 'profile', 'placeholder'])(SignUpStepCreateAccount);
