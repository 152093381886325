import PropTypes from 'prop-types';
import React from 'react';
import MosaicList from '_core/modules/components/lists/components/MosaicList';
import ClassroomsSimpleItem from '../ClassroomsSimpleItem';

const ClassroomsSimpleList = ({ classrooms, columns, onDelete }) => {
  return (
    <div>
      <MosaicList
        columns={columns}
        items={classrooms.map((item) => (
          <ClassroomsSimpleItem {...item} onDelete={onDelete} />
        ))}
      />
    </div>
  );
};

ClassroomsSimpleList.propTypes = {
  columns: PropTypes.number,
  classrooms: PropTypes.array,
};

ClassroomsSimpleList.defaultProps = {
  classrooms: [],
  columns: 2,
};

export default ClassroomsSimpleList;
