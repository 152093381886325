export const ROUTES = {
  ABOUT: '/about',
  ANSWER_TEST: '/answer-test',
  ASSESSMENT: '/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid',
  ASSESSMENT_QUESTION: '/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid/question/:contentGuid',
  ASSESSMENT_RESULT: '/course/:courseGuid/lesson/:lessonGuid/results-assessment/:assessmentGuid',
  AUTH: '/auth',
  AUTH_LOGIN: '/auth/login',
  AUTH_LOGIN_TOKEN: '/auth/login/:token',
  CALENDAR: '/calendar',
  CALENDAR_COURSE: '/calendar/:course',
  CLASSROOM_USERS: '/classroom/:guid/users',
  CONTENT: '/course/:courseGuid/lesson/:lessonGuid/content/:contentGuid',
  COURSE: '/course/:guid',
  COURSE_GRADES: '/course/:guid/grades',
  COURSE_PROGRAM: '/course/:guid/program',
  COURSE_RESOURCES: '/course/:guid/resources',
  COURSE_RESOURCES_DEMO: '/course/:guid/resources-demo',
  CREATE_EVALUATION: '/course/:courseGuid/create-evaluations/:idResourcesSection/:idResourcesGroup',
  CUSTOM_LESSON: '/course/:courseGuid/unit/:unitGuid/lesson/:lessonGuid',
  CUSTOM_LESSON_DIGEST: '/course/:courseGuid/unit/:unitGuid/lesson/:lessonGuid/digest',
  CUSTOM_LESSON_UNIT: '/course/:courseGuid/unit/:unitGuid',
  DEEPLINK: '/deeplink',
  DEEPLINK_TOKEN: '/auth/login/:token/deeplink',
  DESKTOP: '/desktop',
  EDIT_EVALUATION: '/course/:courseGuid/edit-evaluations/:idResourcesSection/:idResourcesGroup/:contentGuid',
  ERROR: '/error',
  EVALUATION: '/course/:guid/resources/:idResourcesSection/:idResourcesGroup/evaluation/:evaluationGuid/:contentGuid',
  EVALUATION_RESULTS: '/course/:guid/resources/view-results/:evaluationGuid/:contentGuid',
  EVALUATION_RESULTS_RESOURCES: '/course/:guid/resources/:idResourcesSection/:idResourcesGroup/view-results/:evaluationGuid/:contentGuid',
  EVALUATION_VIEW: '/course/:guid/resources/view-evaluation/:evaluationGuid/:contentGuid/user/:userGuid',
  EVALUATION_VIEW_RESOURCES: '/course/:guid/resources/:idResourcesSection/:idResourcesGroup/view-evaluation/:evaluationGuid/:contentGuid',
  HOME: '/home',
  KANBAN_LESSON: '/course/:guid/kanban/:lessonGuid',
  LEMON_TEST: '/lemon-test',
  LESSON: '/course/:courseGuid/lesson/:lessonGuid',
  LICENSE: '/license',
  LOADING_DEMO: '/loadingDemo',
  LOGOUT: '/logout',
  NEW_CONTENT: '/course/:courseGuid/lesson/:lessonGuid/new-content',
  NEW_QUESTION: '/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid/new-question/:lemonadeType',
  PRESCHOOL_RESOURCES: '/preschool-resources',
  PRIVACY: '/privacy',
  PROFILE: '/profile',
  PROJECTION: '/course/:courseGuid/lesson/:lessonGuid/projection',
  PROJECTION_MINT: '/course/:courseGuid/lesson/:lessonGuid/projection/:unitGuid',
  RECOVER_PASSWORD: '/recover-password',
  REMOTE: '/remote',
  REMOTE_TOKEN: '/remote/:shortToken',
  RESOURCES_PAGE: '/course/:guid/resources/:idResourcesSection/:idResourcesGroup',
  SIGNUP: '/auth/signup',
  SINGLE_ACTIVITY: '/single-activity/:courseGuid/:lessonItemGuid/:contentGuid',
  SINGLE_ACTIVITY_RECORD: '/single-activity/:courseGuid/record/:contentGuid/:referenceGuid',
  SUPPORT: '/support',
  TAB: '/tab',
  TASKS: '/tasks',
  TEAMS_CONFIG: '/config',
  TEAMS_TAB_AUTH_END: '/tab-auth-end',
  TEAMS_TAB_AUTH_START: '/tab-auth-start',
  TERMS: '/terms',
  TOOLS: '/tools/:courseProjectGuid',
  UI: '/ui',
  VIEWER: '/viewer/:guid',
  VIEWER_CLASS: '/viewer/:guid/:className',
  VIEWER_FULL: '/viewerfull/:guid',
  VIEWER_FULL_GROUP: '/viewerfull/:guid/group-resources-view--visor',
  VIEWER_GROUP: '/viewer/:guid/group-resources-view--visor',
};
