import { isNativeAppWebview } from '_core';
import { useTranslate } from '_core/hooks/useTranslate';
import { onClickSignup, onSetRol, onSetUsername } from '_core/modules/components/ssoOraculo/serviceOraculo';
import * as coreEntities from '_core/store/index';
import { isTeacher } from '_core/utils/user';
import PopupLarge from 'app/modules/components/dialogs/PolpDialogs/PopupLarge/PopupLarge';
import PopupSmall from 'app/modules/components/dialogs/PolpDialogs/PopupSmall/PopupSmall';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function usePolpPopup() {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const showPopup = useSelector((state) => coreEntities.ui.selectors.getShowMigrationPopup(state));
  const currentUser = useSelector((state) => coreEntities.auth.selectors.getUser(state));

  const [needsMigration, setNeedsMigration] = useState(false);
  const [showSmallPopup, setShowSmallPopup] = useState(true);

  useEffect(() => {
    if (window.setUsername) {
      if (currentUser?.username) {
        onSetUsername(currentUser.username);
      } else {
        onSetUsername('');
      }
    }
  }, [currentUser, window.setUsername]);

  useEffect(() => {
    setNeedsMigration(
      currentUser && !currentUser.oraculo_id && isTeacher(currentUser) && currentUser.source !== 'lti' && !isNativeAppWebview() && currentUser.is_demo === 0
    );
  }, [currentUser]);

  useEffect(() => {
    dispatch(coreEntities.ui.actions.changeShowMigrationPopup(needsMigration));
  }, [needsMigration]);

  function onRegister() {
    onSetUsername(currentUser.username);
    if (isTeacher(currentUser)) {
      onSetRol('teacher');
    }
    onClickSignup();
  }

  function closePopup() {
    dispatch(coreEntities.ui.actions.changeShowMigrationPopup(false));
    setShowSmallPopup(true);
  }

  function closeSmallPopup() {
    setShowSmallPopup(false);
  }

  const BigPopup = () => {
    return needsMigration && <PopupLarge t={t} onRegister={onRegister} />;
  };

  const SmallPopup = () => {
    return showSmallPopup ? (
      <div className="polp-popup__small">
        <PopupSmall t={t} onRegister={onRegister} onClose={closeSmallPopup} />
      </div>
    ) : (
      <></>
    );
  };

  return { needsMigration, BigPopup, SmallPopup, showPopup, showSmallPopup, closePopup, onRegister };
}
