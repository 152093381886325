import { clientIdMicrosoft, TERMS } from 'app/config/config';
import initLayoutConfig from 'app/config/layout/LayoutConfig';
import LandingEdelvivesPlus from 'app/modules/compositions/LandingEdelvivesPlus/LandingEdelvivesPlus';
import * as Msal from 'msal';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { frontoffice, parseNavigatorLanguage, successResponse } from '_core';
import { loginNoPublisher, loginSSO, signupUser, validateLicense } from '_core/crud/auth.crud';
import SignUpView from '_core/lite/views/SignUpView';
import Loading from '_core/modules/atoms/Loading';
import * as coreEntities from '_core/store/index';
import { parseProgramsSignup } from '_core/utils/parses';
import { licenseType, role } from '_core/variables/constant';

const SignUpPage = ({ t }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [licenseCodes, setLicenseCodes] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [user, setUser] = useState({
    name: null,
    surname: null,
    password: null,
    email: null,
    terms: false,
    role: null,
    birthdate: null,
    identity_provider: null,
    token: null,
    lang_id: parseNavigatorLanguage(navigator.language),
  });
  const [authorization, setAuthorization] = useState(null);
  const [errorForm, setErrorForm] = useState({});
  const [isSkippable, setIsSkippable] = useState(false);
  const { addToast } = useToasts();

  const educationLevels = useSelector((state) => frontoffice.educationLevels.selectors.getEducationLevels(state));
  const disciplines = useSelector((state) => frontoffice.educationLevels.selectors.getDisciplines(state));

  useEffect(() => {
    onChangeSkippable(step === 4 && classrooms.length === 0);
  }, [classrooms]);

  useEffect(() => {
    if (licenses.length === 0) setUser({ ...user, role: null }); //IF we remove all license, remove also the role guid
  }, [licenses]);

  async function onCheckLicense(data) {
    if (!licenseCodes.includes(data.code)) {
      setLoading(true);
      const response = await validateLicense(`${data.code}`);
      setLoading(false);
      if (response && response.data && response.data.status === 'success') {
        if (response.data.data.type === licenseType.personal) getLicensesPrograms(response.data.data);
        else if (response.data.data.type === licenseType.school) getLicensesClassroom(response.data.data);
      } else {
        getError(response);
      }
    } else {
      addToast(t('signup:You already added this licence'), { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 });
    }
  }

  async function getLicensesPrograms(licenseToParse) {
    try {
      /*if (step > 0) {
        onError(t('signup:Code not found'));
      } else*/ {
        if (user.role && user.role !== licenseToParse.role_guid) {
          addToast('Ya tienes una licencia de otro perfil', { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 });
        } else {
          if (licenseToParse.programs.length === 0) {
            addToast(t('signup:It seems that this license does not have any associated content yet'), {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
          }
          const licensesNew = parseProgramsSignup([...licenses, ...licenseToParse.programs]);
          setLicenses(licensesNew);
          setLicenseCodes([...licenseCodes, licenseToParse.code]);
          setUser({ ...user, role: licenseToParse.role_guid });
        }
      }
    } catch (e) {
      onError(t('common:An error has occurred'));
    }
  }

  async function getLicensesClassroom(licenseToParse) {
    try {
      if (step === 0) {
        onError(t('signup:Code not found'));
      } else {
        const licensesNew = [
          ...classrooms,
          { name: licenseToParse.name, code: licenseToParse.code, level: licenseToParse.education_year_name + ' ' + licenseToParse.education_level_name },
        ];
        setClassrooms(licensesNew);
        setLicenseCodes([...licenseCodes, licenseToParse.code]);
      }
    } catch (e) {
      onError(t('common:An error has occurred'));
    }
  }

  async function signUp() {
    setLoading(true);
    let payload = {
      role: user.role === role.student ? 'student' : 'teacher',
      name: user.name.trim(),
      lastname: user.surname.trim(),
      email: user.email.trim(),
      username: user.email.trim(),
      licenses: licenseCodes,
      lang_id: user.lang_id,
    };

    if (user.identity_provider) {
      payload.sso_token = user.token;
      payload.identity_provider = user.identity_provider;
    } else {
      payload.password = user.password.trim();
    }

    if (authorization && authorization.need) {
      payload.parent_consent = 1;
      payload.parent_email = authorization.parentEmail;
    }

    const response = await signupUser(payload);
    if (successResponse(response)) {
      setTimeout(() => {
        if (user.identity_provider) {
          loginSSO(user.token, user.identity_provider)
            .then((data) => {
              doLogin(data);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          loginNoPublisher(user.email, user.password.trim())
            .then((data) => {
              doLogin(data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }, 1000);
    } else if (
      response.error &&
      response.error.data &&
      response.error.data.status &&
      response.error.data.status === 'fail' &&
      response.error.data.error &&
      response.error.data.error.error &&
      response.error.data.error.error === 'USERNAME_EXIST'
    ) {
      onChangeErrorForm({ email: t('signup:User already exists') });
      setLoading(false);
      setStep(2);
    }
  }

  async function onCreateClassroom(data) {}

  function doLogin(data) {
    if (data.data.data.token) {
      setLoading(true);
      dispatch(coreEntities.auth.actions.login(data.data.data));
    }
    /*if (isTeacher(user.role)) {
      dispatch(coreEntities.ui.actions.setShowCreateClass(true));
    }*/
  }

  function getError(response) {
    if (response.error && response.error.data && response.error.data.error && response.error.data.error.code === 404) {
      onError(t('signup:Code not found'));
    } else {
      onError(t('common:An error has occurred'));
    }
  }

  function onError(text) {
    addToast(text, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 });
    setLoading(false);
  }

  function onChange(payload) {
    setUser(payload);
  }

  function changeAuthorization(payload) {
    setAuthorization(payload);
  }

  function onDeleteLicense(item) {
    setLicenses(licenses.filter((license) => license.code !== item.code));
    setLicenseCodes(licenseCodes.filter((license) => license !== item.code));
  }

  function onCleanLicenses() {
    setLicenses([]);
    setLicenseCodes([]);
  }

  function onDeleteClass(code) {
    setClassrooms(classrooms.filter((classroom) => classroom.code !== code));
    setLicenseCodes(licenseCodes.filter((license) => license !== code));
  }

  function onChangeErrorForm(payload) {
    setErrorForm(payload);
  }

  function onSuccessGoogle(response) {
    const { profileObj } = response;
    setUser({
      ...user,
      email: profileObj.email,
      name: profileObj.givenName,
      surname: profileObj.familyName,
      identity_provider: 'google',
      token: response.tokenId,
    });
  }

  function onFailureGoogle(response) {
    console.log('on failure google: ', response);
  }

  function onChangeSkippable(skip) {
    setIsSkippable(skip);
  }

  function returnInTheFirstStep() {
    history.push(`/auth/login`);
  }

  const onLoginMicrosoft = (onSubmitSSO, onErrorSSO) => {
    const clientId = clientIdMicrosoft[window.location.origin];
    const config = { auth: { clientId, redirectUri: window.location.origin } };

    let myMSALObj = new Msal.UserAgentApplication(config);
    let requestObj = { scopes: ['user.read'], prompt: 'select_account' };

    myMSALObj
      .loginPopup(requestObj)
      .then(async function (loginResponse) {
        myMSALObj
          .acquireTokenSilent(requestObj)
          .then(async (response) => {
            let name = response.account.name || '';
            let surname = response.account.name || '';
            if (response.account.name && response.account.name.split(' ').length > 1) {
              name = response.account.name.split(' ')[0];
              surname = response.account.name.split(' ')[1];
            }
            setUser({
              ...user,
              email: response.account.userName,
              name: name,
              surname: surname,
              identity_provider: 'microsoft',
              token: response.accessToken,
            });
          })
          .catch(function (error) {
            console.error('SSO ms error: ', error);
            myMSALObj
              .acquireTokenPopup(requestObj)
              .then(async function (response) {
                setUser({
                  ...user,
                  email: response.account.userName,
                  name: response.account.name,
                  surname: response.account.name,
                  identity_provider: 'microsoft',
                  token: response.accessToken,
                });
              })
              .catch(function (error) {
                onErrorSSO && onErrorSSO(error);
              });
          });
      })
      .catch(function (error) {
        onErrorSSO && onErrorSSO(error);
      });
  };

  return (
    <div>
      <SignUpView
        configProgramPicture={initLayoutConfig.programPicture}
        step={step}
        changeStep={(newStep) => setStep(newStep)}
        onCheckLicense={onCheckLicense}
        licenses={licenses}
        licenseCodes={licenseCodes}
        onDeleteLicense={onDeleteLicense}
        user={user}
        changeUser={onChange}
        changeAuthorization={changeAuthorization}
        authorization={authorization}
        classrooms={classrooms}
        onDeleteClass={onDeleteClass}
        onEnd={signUp}
        changeErrorForm={onChangeErrorForm}
        errorForm={errorForm}
        onSuccessGoogle={onSuccessGoogle}
        onFailureGoogle={onFailureGoogle}
        onLoginMicrosoft={onLoginMicrosoft}
        cleanLicenses={onCleanLicenses}
        isSkippable={isSkippable}
        changeIsSkippable={onChangeSkippable}
        onCreateTeacher={signUp}
        onCreateClassroom={onCreateClassroom}
        educationLevels={educationLevels}
        disciplines={disciplines}
        returnInTheFirstStep={returnInTheFirstStep}
        layoutRight={<LandingEdelvivesPlus />}
        terms={TERMS}
      />
      {loading && <Loading />}
    </div>
  );
};
export default withTranslation(['signup', 'common'])(SignUpPage);
