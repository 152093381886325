import InputText from '_core/modules/atoms/InputText';
import SelectCustomizable, { utilsSelectCustomizable } from '_core/modules/atoms/SelectCustomizable';
import { getEducationLevelLabel, getEducationYearsLabel } from '_core/utils/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

const ClassroomForm = ({ t, onChange, educationLevels, info }) => {
  const [currentLevel, setCurrentLevel] = useState(null);
  const PARSE_EDUCATION_LEVELS = utilsSelectCustomizable.getOptionsProperties(getEducationLevelLabel(educationLevels, t), 'level', 'guid');

  useEffect(() => {
    if (info.educationLevel) {
      setCurrentLevel(educationLevels.find((item) => item.guid === info.educationLevel));
    }
  }, [info]);

  function onChangeLevel(data) {
    setCurrentLevel(educationLevels.find((item) => item.guid === data.value));
    onChange({ name: 'educationLevel', value: data.value });
  }

  function findEducationYears() {
    return utilsSelectCustomizable.getOptionsProperties(getEducationYearsLabel(currentLevel.educationYears, t), 'year', 'guid');
  }

  function showClassnameError() {
    if (info.name && info.name.length < 2) return t('new-course:The classroom name must contain at least 2 characters');
    if (info.name && info.name.length > 199) return t('new-course:The classroom name is too long');
  }

  return (
    <div className="course-form">
      <div className="course-form__form">
        <div className="course-form__item course-form__item--all-width  ">
          <InputText
            error={showClassnameError()}
            name="name"
            label={t('common:Name')}
            placeholder={t("placeholder:For example 'Group A'")}
            onChange={(e) => onChange({ name: 'name', value: e.currentTarget.value })}
            value={info.name}
          />
        </div>

        <div className="course-form__item course-form__item--middle-width">
          <SelectCustomizable
            options={PARSE_EDUCATION_LEVELS}
            name="education-level"
            label={t('Education level')}
            placeholder={t('placeholder:Select')}
            onChange={onChangeLevel}
            value={PARSE_EDUCATION_LEVELS.find((item) => item.value === info.educationLevel)}
            isSearchable={false}
          />
        </div>
        <div className="course-form__item course-form__item--middle-width">
          <SelectCustomizable
            options={currentLevel ? utilsSelectCustomizable.getOptionsProperties(getEducationYearsLabel(currentLevel.educationYears, t), 'year', 'guid') : []}
            name="education-year"
            label={t('Year')}
            placeholder={t('placeholder:Select')}
            onChange={(data) => onChange({ name: 'educationYear', value: data.value })}
            isDisabled={!currentLevel}
            value={
              currentLevel
                ? findEducationYears().find((item) => item.value === info.educationYear)
                  ? findEducationYears().find((item) => item.value === info.educationYear)
                  : null
                : null
            }
            isSearchable={false}
          />
        </div>
      </div>
    </div>
  );
};

ClassroomForm.propTypes = {
  defaultCurrentGradient: PropTypes.string,
  info: PropTypes.object,
};

ClassroomForm.defaultProps = {
  info: {},
};

export default withTranslation(['new-course', 'placeholder', 'common', 'education-levels', 'education-years'])(ClassroomForm);
