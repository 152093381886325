/* eslint-disable camelcase */
import auth_ca from './ca/auth.json';
import calendar_ca from './ca/calendar.json';
import common_ca from './ca/common.json';
import courses_ca from './ca/courses.json';
import demo_ca from './ca/demo.json';
import editorial_ca from './ca/editorial.json';
import grades_ca from './ca/grades.json';
import header_ca from './ca/header.json';
import kanban_ca from './ca/kanban.json';
import lemonade_ca from './ca/lemonade.json';
import lesson_ca from './ca/lesson.json';
import profile_ca from './ca/profile.json';
import project_ca from './ca/project.json';
import signup_ca from './ca/signup.json';
import tasks_ca from './ca/tasks.json';
import test_ca from './ca/test.json';

import auth_es from './es/auth.json';
import calendar_es from './es/calendar.json';
import classroom_es from './es/classroom.json';
import common_es from './es/common.json';
import courses_es from './es/courses.json';
import demo_es from './es/demo.json';
import editorial_es from './es/editorial.json';
import grades_es from './es/grades.json';
import header_es from './es/header.json';
import kanban_es from './es/kanban.json';
import lemonade_es from './es/lemonade.json';
import lesson_es from './es/lesson.json';
import profile_es from './es/profile.json';
import project_es from './es/project.json';
import signup_es from './es/signup.json';
import tasks_es from './es/tasks.json';
import test_es from './es/test.json';

import auth_en from './en/auth.json';
import calendar_en from './en/calendar.json';
import classroom_en from './en/classroom.json';
import common_en from './en/common.json';
import courses_en from './en/courses.json';
import demo_en from './en/demo.json';
import editorial_en from './en/editorial.json';
import grades_en from './en/grades.json';
import header_en from './en/header.json';
import kanban_en from './en/kanban.json';
import lemonade_en from './en/lemonade.json';
import lesson_en from './en/lesson.json';
import profile_en from './en/profile.json';
import project_en from './en/project.json';
import signup_en from './en/signup.json';
import tasks_en from './en/tasks.json';
import test_en from './en/test.json';

import auth_eu from './eu/auth.json';
import calendar_eu from './eu/calendar.json';
import classroom_eu from './eu/classroom.json';
import common_eu from './eu/common.json';
import courses_eu from './eu/courses.json';
import demo_eu from './eu/demo.json';
import editorial_eu from './eu/editorial.json';
import grades_eu from './eu/grades.json';
import header_eu from './eu/header.json';
import kanban_eu from './eu/kanban.json';
import lemonade_eu from './eu/lemonade.json';
import lesson_eu from './eu/lesson.json';
import profile_eu from './eu/profile.json';
import project_eu from './eu/project.json';
import signup_eu from './eu/signup.json';
import tasks_eu from './eu/tasks.json';
import test_eu from './eu/test.json';

import auth_gl from './gl/auth.json';
import calendar_gl from './gl/calendar.json';
import classroom_gl from './gl/classroom.json';
import common_gl from './gl/common.json';
import courses_gl from './gl/courses.json';
import demo_gl from './gl/demo.json';
import editorial_gl from './gl/editorial.json';
import grades_gl from './gl/grades.json';
import header_gl from './gl/header.json';
import kanban_gl from './gl/kanban.json';
import lemonade_gl from './gl/lemonade.json';
import lesson_gl from './gl/lesson.json';
import profile_gl from './gl/profile.json';
import project_gl from './gl/project.json';
import signup_gl from './gl/signup.json';
import tasks_gl from './gl/tasks.json';
import test_gl from './gl/test.json';

import auth_vl from './vl/auth.json';
import calendar_vl from './vl/calendar.json';
import { default as classroom_ca, default as classroom_vl } from './vl/classroom.json';
import common_vl from './vl/common.json';
import courses_vl from './vl/courses.json';
import demo_vl from './vl/demo.json';
import editorial_vl from './vl/editorial.json';
import grades_vl from './vl/grades.json';
import header_vl from './vl/header.json';
import kanban_vl from './vl/kanban.json';
import lemonade_vl from './vl/lemonade.json';
import lesson_vl from './vl/lesson.json';
import profile_vl from './vl/profile.json';
import project_vl from './vl/project.json';
import signup_vl from './vl/signup.json';
import tasks_vl from './vl/tasks.json';
import test_vl from './vl/test.json';

import auth_mx from './mx/auth.json';
import calendar_mx from './mx/calendar.json';
import classroom_mx from './mx/classroom.json';
import common_mx from './mx/common.json';
import courses_mx from './mx/courses.json';
import demo_mx from './mx/demo.json';
import editorial_mx from './mx/editorial.json';
import grades_mx from './mx/grades.json';
import header_mx from './mx/header.json';
import kanban_mx from './mx/kanban.json';
import lemonade_mx from './mx/lemonade.json';
import lesson_mx from './mx/lesson.json';
import profile_mx from './mx/profile.json';
import project_mx from './mx/project.json';
import signup_mx from './mx/signup.json';
import tasks_mx from './mx/tasks.json';
import test_mx from './mx/test.json';

export default {
  es: [
    auth_es,
    courses_es,
    common_es,
    header_es,
    calendar_es,
    lesson_es,
    profile_es,
    signup_es,
    tasks_es,
    test_es,
    classroom_es,
    lemonade_es,
    grades_es,
    project_es,
    editorial_es,
    demo_es,
    kanban_es,
  ],
  gl: [
    auth_gl,
    courses_gl,
    common_gl,
    header_gl,
    calendar_gl,
    lesson_gl,
    profile_gl,
    signup_gl,
    tasks_gl,
    test_gl,
    classroom_gl,
    lemonade_gl,
    grades_gl,
    project_gl,
    editorial_gl,
    demo_gl,
    kanban_gl,
  ],
  eu: [
    auth_eu,
    courses_eu,
    common_eu,
    header_eu,
    calendar_eu,
    lesson_eu,
    profile_eu,
    signup_eu,
    tasks_eu,
    test_eu,
    classroom_eu,
    lemonade_eu,
    grades_eu,
    project_eu,
    editorial_eu,
    demo_eu,
    kanban_eu,
  ],
  vl: [
    auth_vl,
    courses_vl,
    common_vl,
    header_vl,
    calendar_vl,
    lesson_vl,
    profile_vl,
    signup_vl,
    tasks_vl,
    test_vl,
    classroom_vl,
    lemonade_vl,
    grades_vl,
    project_vl,
    editorial_vl,
    demo_vl,
    kanban_vl,
  ],
  ca: [
    auth_ca,
    courses_ca,
    common_ca,
    header_ca,
    calendar_ca,
    lesson_ca,
    profile_ca,
    signup_ca,
    tasks_ca,
    test_ca,
    classroom_ca,
    lemonade_ca,
    grades_ca,
    project_ca,
    editorial_ca,
    demo_ca,
    kanban_ca,
  ],
  en: [
    auth_en,
    courses_en,
    common_en,
    header_en,
    calendar_en,
    lesson_en,
    profile_en,
    signup_en,
    tasks_en,
    test_en,
    classroom_en,
    lemonade_en,
    grades_en,
    project_en,
    editorial_en,
    demo_en,
    kanban_en,
  ],
  'es-MX': [
    auth_mx,
    courses_mx,
    common_mx,
    header_mx,
    calendar_mx,
    lesson_mx,
    profile_mx,
    signup_mx,
    tasks_mx,
    test_mx,
    classroom_mx,
    lemonade_mx,
    grades_mx,
    project_mx,
    editorial_mx,
    demo_mx,
    kanban_mx,
  ],
};
