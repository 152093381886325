import Logo from '_core/modules/atoms/Logo/Logo';
import AddLicenseForm from '_core/modules/components/forms/AddLicenseForm';
import LicensesList from '_core/modules/components/lists/licenses/LicensesList';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';

const SignUpStepLicense = ({
  t,
  configProgramPicture,
  onChangeDisabledButton,
  changeTextButton,
  onDeleteLicense,
  hasMoreLicenses,
  loadMoreLicenses,
  licenses,
  licenseCodes,
  onCheckLicense,
  layoutRight,
  step,
}) => {
  changeTextButton(t('actions:Continue'));

  useEffect(() => {
    if (licenseCodes.length > 0) {
      onChangeDisabledButton(false);
    }
  }, [licenseCodes]);

  function onSubmit(data) {
    onCheckLicense(data);
  }

  return (
    <div className="signup-step-license signup-step-license--with-licenses">
      <div className="signup__step">
        <div className="signup__column1">
          <div className="signup__step-content">
            <div className="signup__logo">
              <Logo size="big" />
            </div>
            <div className="signup__header">
              <span className="signup__titlepart1">
                {t('Registrations')}&nbsp;<span className="signup__titlepart2">{t('Licenses')}</span>{' '}
              </span>
            </div>
            <span className="signup__description">{t('Add your licenses to continue')}</span>
            <div className="signup-step-license__form">
              <AddLicenseForm onSubmit={onSubmit} />
            </div>
            <div className="signup-step-license__licenses">
              <LicensesList
                configProgramPicture={configProgramPicture}
                typeView="simple"
                licenses={licenses}
                loadMore={loadMoreLicenses}
                hasMore={hasMoreLicenses}
                onDelete={onDeleteLicense}
              />
            </div>
          </div>
        </div>

        <div className="signup__column2">{layoutRight || <div className={clsx('signup__image', `signup__image--step${step}`)}></div>}</div>
      </div>
    </div>
  );
};

SignUpStepLicense.propTypes = {
  onDeleteLicense: PropTypes.func,
  licenses: PropTypes.array,
  onCheckLicense: PropTypes.func,
  step: PropTypes.number,
};

SignUpStepLicense.defaultProps = {
  licenses: [],
  step: 1,
  onDeleteLicense: function () {
    return null;
  },
  onCheckLicense: function () {
    return null;
  },
  loadMoreLicenses: function () {
    return null;
  },
};

export default withTranslation(['signup', 'actions', 'placeholder'])(SignUpStepLicense);
