import { cleanEventName } from '_core/lite/parsers';
import { parseUser } from '_core/utils/parses';
import { getGradientByDisciplineCourse, stripHtml } from '_core/utils/utils';
import { notificationsSubtypes } from 'app/utils/notifications';
import i18next from 'i18next';
import moment from 'moment';

export function parseTasksForTaskPage(t, tasks) {
  return tasks.map((item) => {
    return {
      ...item,
      lessonName: stripHtml(item.lesson.name),
      section: stripHtml(item.section.name),
      name: item.lesson_item_name,
      title: t('assigments:Activity') + ' ' + item.lesson_item_name,
      date: item.lesson_item_deliver_at ? moment(item.lesson_item_deliver_at).format('DD-MM-YYYY HH:mm') : null,
      dueDate: item.lesson_item_deliver_at,
      pending: item.jobs_delivered,
      evaluated: item.jobs_evaluated,
      noStarted: item.jobs_amount - item.jobs_delivered - item.jobs_evaluated,
      total: item.jobs_amount,
      participation: 20,
      autoevaluative: false,
    };
  });
}

export function parseTaskStudent(t, tasks = []) {
  return tasks.map((item) => {
    return {
      ...item,
      lessonName: stripHtml(item.lesson.name),
      section: item.section.name,
      sectionGuid: item.section.guid,
      name: item.lesson_item_name,
      title: t('assigments:Activity') + ' ' + item.lesson_item_name,
      date: moment(item.lesson_item_deliver_at).format('DD-MM-YYYY HH:mm'),
      dueDate: item.lesson_item_deliver_at,
      pending: item.jobs_delivered,
      evaluated: item.jobs_evaluated,
      noStarted: item.jobs_amount,
      questionsLength: item.test_amount_questions,
      status: parseTaskStatus(item),
      grade: item.job_score,
      gradeTotal: item.lesson_item_ranking_scale,
    };
  });
}

export function parseTaskStatus(task = {}) {
  const hoursLeft = moment(new Date(task.lesson_item_deliver_at)).diff(moment(new Date()), 'hours');
  const isLate = moment(new Date(task.lesson_item_deliver_at)).isBefore(new Date());

  if (task.job_status === 'pending' && isLate) return 'late';
  if (task.job_status === 'pending' && hoursLeft < 24) return 'soon';
  if (task.job_status === 'pending' && task.job_evaluated_by) return 'assessment-reassigned';
  if (task.job_status === 'pending' && hoursLeft > 24) return 'assessment-assigned';
  if (task.job_status === 'delivered') return 'turned-in';
  if (task.job_status === 'evaluated') return 'assessment-evaluated';
}

export function parseEvents(events = []) {
  return events.map((item) => {
    return {
      ...item,
      name: item.title === 'CLASS' ? `(${i18next.t('lesson:No lesson assigned')})` : cleanEventName(item.title),
      title: item.title === 'CLASS' ? `(${i18next.t('lesson:No lesson assigned')})` : cleanEventName(item.title),
      start: item.start_at,
      type: item.type_name,
      course: {
        gradient: item.education_discipline_guid ? getGradientByDisciplineCourse(item.education_discipline_guid) : null,
        name: item.school_group_name,
      },
      lesson: {
        name: item.title === 'CLASS' ? `(${i18next.t('lesson:No lesson assigned')})` : cleanEventName(item.title),
      },
    };
  });
}

export function parseJobUsers(jobs = []) {
  return jobs.map((job) => {
    return parseJobUser(job);
  });
}

export function parseJobUser(job = {}) {
  return {
    ...job,
    job_guid: job.guid,
    name: job.student_name,
    lastName: job.student_lastname,
    email: job.student_email,
    guid: job.person_guid,
    score: job.score,
    thumbnail: job.student_avatar,
    turned: job.status !== 'pending',
  };
}

function getTextNotification(data, notification) {
  let text = data.message && data.message.parent_message ? data.message.parent_message.message : '';
  if (notification.type === notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT) {
    text = data.message.parentMessageData.lesson_item_content_name;
  }
  return text;
}

export function parseOneNotification(item) {
  try {
    let data = JSON.parse(item.data);
    return {
      ...item,
      user: parseUser(data.user || data.student),
      data,
      viewAt: item.viewed_at,
      date: item.created_at,
      subtext: data.message ? data.message.message : null,
      text: getTextNotification(data, item),
      className: data.school_group ? data.school_group.name : '',
      courseName: data.course.name,
      consumedAt: item.consumed_at,
      unitName: data.section ? data.section.name : null,
      assessmentName: data.assesment ? data.assesment.name : null,
      isEvaluable: data.assesment && data.assesment.is_evaluable ? data.assesment.is_evaluable : 0,
      dueDate: data.deliver_at,
      course: {
        gradient: getGradientByDisciplineCourse(data.course.discipline_guid),
      },
    };
  } catch (e) {
    console.log(e, 'error parseOneNotification');
    return null;
  }
}
