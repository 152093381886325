import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Icon from './../Icon';
import InputText from './../InputText';
import useInputPassword from './useInputPassword';

const InputPassword = ({ error, t, ...props }) => {
  const { visible, onVisible } = useInputPassword();

  return (
    <div className="input-password">
      <InputText error={error} type={visible ? 'text' : 'password'} onClickIcon={onVisible} icon={visible ? 'visible' : 'visible-off'} {...props} />
      {/* {error && (
        <Tooltip title={t('Password restrictions', { length: 3 })} placement="top">
          <div className="input-password__icon">
            <Icon type="info-circle" />{' '}
          </div>
        </Tooltip>
      )} */}
    </div>
  );
};

InputPassword.propTypes = {
  error: PropTypes.string,
};

InputPassword.defaultProps = {};

export default withTranslation('auth')(InputPassword);
