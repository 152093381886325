import ErrorText from '_core/modules/atoms/ErrorText';
import InputCheckbox from '_core/modules/atoms/InputCheckbox';
import InputPassword from '_core/modules/atoms/InputPassword';
import InputText from '_core/modules/atoms/InputText';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

const AccountForm = ({ t, onChange, error, onAcceptTerms, onOpenTerms, user }) => {
  return (
    <div className="account-form">
      <div className="account-form__field">
        <InputText
          //disabled={user.identity_provider}
          placeholder={t('Name')}
          onChange={onChange}
          name="name"
          error={error.name ? <ErrorText text={error['name']} /> : null}
          value={user.name || ''}
        />
      </div>

      <div className="account-form__field">
        <InputText
          //disabled={user.identity_provider}
          placeholder={t('Surname')}
          onChange={onChange}
          name="surname"
          error={error.surname ? <ErrorText text={error['surname']} /> : null}
          value={user.surname || ''}
        />
      </div>

      <div className="account-form__field">
        <InputText
          disabled={user.identity_provider}
          autoComplete="off"
          iconLeft={true}
          placeholder={t('signup:Email')}
          onChange={onChange}
          name="email"
          icon="email"
          error={error.email ? <ErrorText text={error['email']} /> : null}
          value={user.email || ''}
        />
      </div>
      {!user.identity_provider && (
        <>
          <div className="account-form__field">
            <InputText
              disabled={user.identity_provider}
              autoComplete="off"
              iconLeft={true}
              placeholder={t('signup:Confirm Email')}
              onChange={onChange}
              name="confirmEmail"
              icon="email"
              error={error.confirmEmail ? <ErrorText text={error['confirmEmail']} /> : null}
              value={user.confirmEmail || ''}
            />
          </div>
          <div className="account-form__field">
            <InputPassword
              autoComplete="off"
              placeholder={t('Password', { count: 3 })}
              onChange={onChange}
              name="password"
              error={error.password ? <ErrorText text={error['password']} /> : null}
              value={user.password || ''}
            />
          </div>
          <div className="account-form__field">
            <InputPassword
              autoComplete="off"
              placeholder={t('signup:Confirm password', { count: 3 })}
              onChange={onChange}
              name="confirmPassword"
              error={error.confirmPassword ? <ErrorText text={error['confirmPassword']} /> : null}
              value={user.confirmPassword || ''}
            />
          </div>
        </>
      )}
      <div className="account-form__field">
        <InputCheckbox
          name="accept"
          onChange={onAcceptTerms}
          checked={user.terms}
          label={
            <a>
              {t('signup:I’ve read and accept')}
              <a href="/terms" target="_blank" className="account-form__terms">
                {' '}
                {t('signup:terms and conditions')}
              </a>
            </a>
          }
        />
        {error.terms && <ErrorText text={error['terms']} />}
      </div>
    </div>
  );
};

AccountForm.propTypes = {
  error: PropTypes.object,
};

AccountForm.defaultProps = {
  error: {},
  onOpenTerms: () => {},
};

export default withTranslation(['profile', 'signup', 'placeholder'])(AccountForm);
