import LayoutProgress from '_core/layout/LayoutProgress';
import { isStudent, isTeacher } from '_core/utils/user';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SignUpStepAuthorization from './SignUpStepAuthorization';
import SignUpStepCreateAccount from './SignUpStepCreateAccount';
import SignUpStepCreateClassroom from './SignUpStepCreateClassroom';
import SignUpStepLicense from './SignUpStepLicense';
import SignUpStepLicenseEmpty from './SignUpStepLicenseEmpty';

const SignupView = ({
  configProgramPicture,
  onDeleteLicense,
  licenses,
  licenseCodes,
  classrooms,
  onCheckLicense,
  user,
  changeUser,
  changeAuthorization,
  authorization,
  onEnd,
  step,
  changeStep,
  onDeleteClass,
  changeErrorForm,
  errorForm,
  onSuccessGoogle,
  onFailureGoogle,
  onLoginMicrosoft,
  cleanLicenses,
  isSkippable,
  changeIsSkippable,
  onCreateTeacher,
  onCreateClassroom,
  educationLevels,
  disciplines,
  returnInTheFirstStep,
  layoutRight,
  terms,
}) => {
  const [progress, setProgress] = useState(0);
  const [disabledButtonContinue, setDisabledButtonContinue] = useState(true);

  useEffect(() => {
    if (licenses && licenses.length > 0) {
      changeStep(1);
      setProgress(20);
    } else {
      changeStep(0);
      setProgress(0);
    }
  }, [licenses]);

  useEffect(() => {
    if (step === 4 /*&& user.role === 'R01'*/) changeIsSkippable(true);
    else changeIsSkippable(false);
  }, [step]);

  function onClose() {}

  function changeDisabledButton(isDisabled) {
    setDisabledButtonContinue(isDisabled);
  }

  function onNext() {
    if (step === 2 && isTeacher(user.role)) {
      //Is a teacher and we have to create it before continue
      onCreateTeacher();
    }

    let next = step + 1;
    if (next === 2) {
      setProgress(40);
    } else if (next == 3) {
      setProgress(80);
    } else if (next == 4) {
      setProgress(100);
    }
    if ((user.role === 'R01' && next < 4) || (user.role === 'R02' && next < 3)) changeStep(next);
  }

  function onBack() {
    let next = step - 1;
    if (next === 0) {
      setProgress(0);
    } else if (next === 1) {
      setProgress(20);
    } else if (next === 2) {
      setProgress(40);
    } else if (next == 3) {
      setProgress(60);
    } else if (next == 4) {
      setProgress(80);
    }
    changeStep(next);
  }

  return (
    <div className="signup">
      <LayoutProgress
        parentStep={step}
        showProgress={true}
        parentProgress={progress}
        onClickIconTypeRight={onClose}
        header=" "
        backgroundColor="white"
        onNext={onNext}
        onBack={onBack}
        onEnd={onEnd}
        disabledButtonParent={disabledButtonContinue}
        isSkippable={isSkippable}
        returnInTheFirstStep={returnInTheFirstStep}
      >
        <SignUpStepLicenseEmpty
          onDeleteLicense={onDeleteLicense}
          onCheckLicense={onCheckLicense}
          onChangeDisabledButton={changeDisabledButton}
          cleanLicenses={cleanLicenses}
          layoutRight={layoutRight}
          step={step}
        />
        <SignUpStepLicense
          configProgramPicture={configProgramPicture}
          onDeleteLicense={onDeleteLicense}
          licenses={licenses}
          licenseCodes={licenseCodes}
          onCheckLicense={onCheckLicense}
          onChangeDisabledButton={changeDisabledButton}
          layoutRight={layoutRight}
          step={step}
        />

        <SignUpStepCreateAccount
          onChangeDisabledButton={changeDisabledButton}
          changeUser={changeUser}
          user={user}
          changeErrorForm={changeErrorForm}
          errorForm={errorForm}
          onSuccessGoogle={onSuccessGoogle}
          onFailureGoogle={onFailureGoogle}
          onLoginMicrosoft={onLoginMicrosoft}
          layoutRight={layoutRight}
          terms={terms}
          step={step}
        />
        {user.role && isStudent(user.role) ? (
          <SignUpStepAuthorization
            onChangeDisabledButton={changeDisabledButton}
            changeAuthorization={changeAuthorization}
            authorization={authorization}
            changeUser={changeUser}
            user={user}
            layoutRight={layoutRight}
            step={step}
          />
        ) : (
          <SignUpStepCreateClassroom
            onChangeDisabledButton={changeDisabledButton}
            classrooms={classrooms}
            onChangeClassroom={onCreateClassroom}
            educationLevels={educationLevels}
            disciplines={disciplines}
            layoutRight={layoutRight}
          />
        )}
        {/* <SignUpStepClass
          classrooms={classrooms}
          onChangeDisabledButton={changeDisabledButton}
          onDeleteClass={onDeleteClass}
          onCheckLicense={onCheckLicense}
          layoutRight={layoutRight}
        /> */}
      </LayoutProgress>
    </div>
  );
};

SignupView.propTypes = {
  licenses: PropTypes.array,
  onDeleteLicense: PropTypes.func,
  onChangeClassroom: PropTypes.func,
  returnInTheFirstStep: PropTypes.func,
};

SignupView.defaultProps = {
  configProgramPicture: false,
  licenses: [],
  onDeleteLicense: function () {
    return null;
  },
  onChangeClassroom: function () {
    return null;
  },
  user: {},
  changeStep: () => {
    return null;
  },
  changeIsSkippable: () => {
    return null;
  },
  cleanLicenses: () => {
    return null;
  },
};

export default SignupView;
