import CoreImage from '_core/variables/images';
import PropTypes from 'prop-types';
import React from 'react';

const ImageGradient = ({ image, noFilter }) => {
  const imageDefault = CoreImage.getImage('image-layout-progress');

  return (
    <div className="image-gradient" style={{ backgroundImage: `url(${image ? image : imageDefault})` }}>
      <div className="image-gradient__gradient" style={{ ...(noFilter && { backgroundImage: 'none' }) }}></div>
    </div>
  );
};

ImageGradient.propTypes = {
  image: PropTypes.string,
  noFilter: PropTypes.bool,
};

ImageGradient.defaultProps = {
  image: CoreImage.getImage('image-layout-progress'),
  noFilter: false,
};

export default ImageGradient;
