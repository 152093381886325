import DialogDefault from '_core/modules/components/dialogs/components/Dialog';
import usePolpPopup from 'app/modules/components/dialogs/PolpDialogs/PolpPopup/usePolpPopup';
import React from 'react';

const PolpPopup = () => {
  const { needsMigration, BigPopup, SmallPopup, showPopup, showSmallPopup, closePopup } = usePolpPopup();

  return (
    <>
      {/* Añadir onClose={closePopup} para poder cerrar el dialogo */}
      <DialogDefault open={showPopup} size="s" title=" " className="polp-popup">
        {BigPopup()}
      </DialogDefault>
      {/* {!showPopup && needsMigration && SmallPopup()} */}
    </>
  );
};

export default PolpPopup;
