import PropTypes from 'prop-types';
import React from 'react';

const ItemComplexList = ({ left, center, right, onClick, className, previewContent }) => {
  return (
    <div>
      <div className={`item-complex-list ${className ? className : ''}`} onClick={onClick}>
        <div className="item-complex-list__left">
          {left && <div className="item-complex-list__left-children">{left}</div>}
          <div className="item-complex-list__center">{center}</div>
        </div>
        <div className="item-complex-list__right">{right}</div>
      </div>
      {previewContent && <>{previewContent}</>}
    </div>
  );
};

ItemComplexList.propTypes = {
  onClick: PropTypes.func,
};

ItemComplexList.defaultProps = {
  onClick: function () {},
};

export default ItemComplexList;
