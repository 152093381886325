import { conf } from '_core/store/';
import { REDUCER_LEARNING_OBJECTIVES } from '_core/store/conf';
import * as coreEntities from '_core/store/index'; // reducers from core frontoffice
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
/* Reducers APP */
import {
  REDUCER_CALENDARS,
  REDUCER_CONTENT,
  REDUCER_COUNTRIES,
  REDUCER_COURSES,
  REDUCER_EDUCATION_LEVELS,
  REDUCER_GENERAL_APP,
  REDUCER_GROUPS,
  REDUCER_LANGS,
  REDUCER_LEMONADE_TEST,
  REDUCER_LESSONS,
  REDUCER_NOTIFICATIONS,
  REDUCER_PROGRAMS,
  REDUCER_PROJECTION,
  REDUCER_SCHOOL_YEARS,
  REDUCER_TESTS,
} from './conf';
import * as appEntities from './index'; // reducers from core frontoffice

//Creamos el reducer del _core a medida con lo estrictamente necesario
const reducersCore = {
  [conf.REDUCER_ORGANIZATION]: coreEntities.organization.reducer,
  [conf.REDUCER_GAMIFICATION]: coreEntities.gamification.reducer,
  [conf.REDUCER_AUTH]: coreEntities.auth.reducer,
  [conf.REDUCER_I18N]: coreEntities.i18n.reducer,
  [conf.REDUCER_UI]: coreEntities.ui.reducer,
  [conf.REDUCER_HISTORY]: coreEntities.history.reducer,
  [conf.REDUCER_DEMO]: coreEntities.demo.reducer,
};

const reducersApp = {
  [REDUCER_GENERAL_APP]: combineReducers({
    [REDUCER_LANGS]: coreEntities.langs.reducer,
    [REDUCER_EDUCATION_LEVELS]: coreEntities.educationLevels.reducer,
    [REDUCER_COUNTRIES]: coreEntities.countries.reducer,
    [REDUCER_LESSONS]: coreEntities.lessons.reducer,
    [REDUCER_CONTENT]: coreEntities.contents.reducer,
    [REDUCER_TESTS]: appEntities.tests.reducer,
    [REDUCER_COURSES]: coreEntities.courses.reducer,
    [REDUCER_PROGRAMS]: coreEntities.programs.reducer,
    [REDUCER_CALENDARS]: coreEntities.calendars.reducer,
    [conf.REDUCER_TASKS]: coreEntities.tasks.reducer,
    [REDUCER_GROUPS]: coreEntities.groups.reducer,
    [REDUCER_SCHOOL_YEARS]: coreEntities.schoolYears.reducer,
    [REDUCER_NOTIFICATIONS]: coreEntities.notifications.reducer,
    [REDUCER_LEMONADE_TEST]: appEntities.lemonadeTest.reducer,
    [REDUCER_PROJECTION]: coreEntities.projection.reducer,
    [REDUCER_LEARNING_OBJECTIVES]: coreEntities.learningObjectives.reducer,
  }),
};

export const rootReducer = combineReducers({ ...reducersCore, ...reducersApp });

export function* rootSaga() {
  yield all([
    coreEntities.langs.saga(),
    coreEntities.auth.saga(),
    coreEntities.educationLevels.saga(),
    coreEntities.countries.saga(),
    coreEntities.lessons.saga(),
    coreEntities.contents.saga(),
    appEntities.tests.saga(),
    appEntities.appInfo.saga(),
    coreEntities.calendars.saga(),
    coreEntities.tasks.saga(),
    coreEntities.courses.saga(),
    coreEntities.groups.saga(),
    coreEntities.schoolYears.saga(),
    coreEntities.notifications.saga(),
    coreEntities.learningObjectives.saga(),
    coreEntities.programs.saga(),
    appEntities.lemonadeTest.saga(),
    coreEntities.organization.saga(),
  ]);
}
