import * as microsoftTeams from '@microsoft/teams-js';
import React from 'react';

const TabAuthEnd = () => {
  //Helper function that converts window.location.hash into a dictionary
  const getHashParameters = () => {
    let hashParams = {};
    window.location.hash
      .substr(1)
      .split('&')
      .forEach(function (item) {
        let [key, value] = item.split('=');
        hashParams[key] = decodeURIComponent(value);
      });
    return hashParams;
  };

  microsoftTeams.app.initialize().then(() => {
    //The Azure implicit grant flow injects the result into the window.location.hash object. Parse it to find the results.
    let hashParams = getHashParameters();

    //If consent has been successfully granted, the Graph access token should be present as a field in the dictionary.
    if (hashParams['access_token']) {
      //Notifify the showConsentDialogue function in Tab.js that authorization succeeded. The success callback should fire.
      microsoftTeams.authentication.notifySuccess(hashParams['access_token']);
    } else {
      microsoftTeams.authentication.notifyFailure('Consent failed');
    }
  });

  return <div>Por favor, cierra esta ventana y vuelve a Microsoft Teams</div>;
};

export default TabAuthEnd;
