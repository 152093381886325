import { put, takeLatest } from '@redux-saga/core/effects';
import { updateContent } from '_core/crud/contents.crud';
import { postNewContent, putSectionOfLesson } from '_core/crud/lesson.crud';
import { getTestQuestions } from '_core/crud/tests.crud';
import { isString } from 'lodash';

export const actionTypes = {
  GetTestQuestions: 'LEMONADE_Test/GET_QUESTIONS',
  SetTestQuestions: 'LEMONADE_Test/SET_QUESTIONS',
  AddQuestions: 'LEMONADE_Test/ADD_QUESTIONS',
  SetScores: 'LEMONADE_Test/SET_SCORES',
  SetTest: 'LEMONADE_Test/SET_TEST',
  DuplicateQuestion: 'LEMONADE_TEST/DUPLICATE_QUESTION',
  DeleteQuestion: 'LEMONADE_TEST/DELETE_QUESTION',
  UpdateScoresManually: 'LEMONADE_TEST/UPDATE_SCORES_MANUALLY',
  UpdateTest: 'LEMONADE_TEST/UPDATE_TEST',
  SetIsLoading: 'LEMONADE_TEST/SET_IS_LOADING',
  GetIsLoading: 'LEMONADE_TEST/GET_IS_LOADING',
  ReplaceQuestion: 'LEMONADE_TEST/REPLACE_QUESTION',
};

const initialState = {
  test: {},
  questions: [],
  scores: [],
  isLoading: true,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetScores: {
      return { ...state, scores: action.payload };
    }

    case actionTypes.SetTestQuestions: {
      return { ...state, questions: action.payload };
    }

    case actionTypes.SetTest: {
      return { ...state, test: action.payload };
    }

    case actionTypes.SetIsLoading: {
      return { ...state, isLoading: action.payload };
    }

    default:
      return { ...state, isLoading: false };
  }
};

export const selectors = {
  getScores: (state) => {
    return (state.entities && state.entities.lemonadeTest && state.entities.lemonadeTest.scores) || [];
  },
  getQuestions: (state) => {
    return (state.entities && state.entities.lemonadeTest && state.entities.lemonadeTest.questions) || [];
  },
  getTest: (state) => {
    return (state.entities && state.entities.lemonadeTest && state.entities.lemonadeTest.test) || {};
  },
  getIsLoading: (state) => {
    return (state.entities && state.entities.lemonadeTest && state.entities.lemonadeTest.isLoading) || false;
  },
};

export const actions = {
  setScores: (scores) => ({ type: actionTypes.SetScores, payload: scores }),
  setTest: (test) => ({ type: actionTypes.SetTest, payload: test }),
  setQuestions: (questions) => ({ type: actionTypes.SetTestQuestions, payload: questions }),
  duplicateQuestion: (payload) => ({ type: actionTypes.DuplicateQuestion, payload }),
  deleteQuestion: (payload) => ({ type: actionTypes.DeleteQuestion, payload }),
  addQuestions: (payload) => ({ type: actionTypes.AddQuestions, payload }),
  updateScoresManually: (payload) => ({ type: actionTypes.UpdateScoresManually, payload }),
  updateTest: (payload) => ({ type: actionTypes.UpdateTest, payload }),
  replaceQuestion: (payload) => ({ type: actionTypes.ReplaceQuestion, payload }),
  setIsLoading: (payload) => ({ type: actionTypes.SetIsLoading, payload }),
};

export function* saga() {
  yield takeLatest(actionTypes.DuplicateQuestion, function* duplicateQuestionSaga(action) {
    yield put(actions.setIsLoading(true));
    //   Add question as content
    const newQuestionAsContent = yield postNewContent({
      name: action.payload.newQuestion.title,
      description: action.payload.newQuestion.description,
      type_guid: 'CTTY_14',
      is_teacher_only: 0,
      data: action.payload.newQuestion.data,
      is_public: 1,
    });
    //   Add questionContent to test
    yield updateContent({
      guid: action.payload.test.content_guid,
      name: action.payload.test.name,
      description: action.payload.test.description || '',
      status: 'active',
      is_public: 1,
      data: {
        ...action.payload.test.data,
        time: action.payload.test.duration || (action.payload.test.data && action.payload.test.data.time) || 0,
        questions: [
          ...action.payload.test.data.questions,
          {
            guid: newQuestionAsContent.data.data.guid,
            ranking_scale: action.payload.newQuestion.ranking_scale,
          },
        ],
      },
    });
    // get updated test with new questions to make sure that local displays remote correctly
    const { data } = yield getTestQuestions({ guid: action.payload.test.content_guid });
    //   Update Redux
    if (data && data.status === 'success' && data.data) {
      yield put(actions.setQuestions(data.data.data.questions));
      yield put(actions.setScores(data.data.data.questions.map((question) => ({ questionGuid: question.question_guid, score: question.ranking_scale }))));
    }
    yield put(actions.setIsLoading(false));
  });

  yield takeLatest(actionTypes.DeleteQuestion, function* deleteQuestionSaga(action) {
    yield put(actions.setIsLoading(true));
    // Set new list of questions without the selected question to delete it
    yield updateContent({
      guid: action.payload.test.content_guid,
      name: action.payload.test.name,
      description: action.payload.test.description || '',
      type_guid: 'CTTY_09',
      is_teacher_only: 0,
      status: 'active',
      is_public: 1,
      data: {
        ...action.payload.test.data,
        time: action.payload.test.duration || (action.payload.test.data && action.payload.test.data.time) || 0,
        questions: action.payload.questions.filter((question) => question !== undefined), // remove the locally managed list undefined items
      },
    });
    // get updated test with new questions to make sure that local displays remote correctly
    const { data } = yield getTestQuestions({ guid: action.payload.test.content_guid });
    //   Update Redux
    if (data && data.status === 'success' && data.data) {
      yield put(actions.setQuestions(action.payload.questions));
      yield put(
        actions.setScores(
          action.payload.questions.map((question) => {
            if (question !== undefined) {
              return {
                questionGuid: question.question_guid,
                score: parseFloat(question.ranking_scale),
              };
            }
            return undefined;
          })
        )
      );
    }
    yield put(actions.setIsLoading(false));
  });

  yield takeLatest(actionTypes.AddQuestions, function* addQuestionsSaga(action) {
    yield put(actions.setIsLoading(true));
    // Set new list of questions without the selected question to delete it
    yield updateContent({
      guid: action.payload.test.content_guid,
      name: action.payload.test.name,
      description: action.payload.test.description || '',
      type_guid: 'CTTY_09',
      is_teacher_only: 0,
      status: 'active',
      is_public: 1,
      data: {
        ...action.payload.test.data,
        questions: [...action.payload.oldQuestions, ...action.payload.newQuestions],
      },
    });
    // get updated test with new questions to make sure that local displays remote correctly
    const { data } = yield getTestQuestions({ guid: action.payload.test.content_guid });
    //   Update Redux
    if (data && data.status === 'success' && data.data) {
      yield put(actions.setQuestions(data.data.data.questions));
      yield put(actions.setScores(data.data.data.questions.map((question) => ({ questionGuid: question.question_guid, score: question.ranking_scale }))));
    }
    yield put(actions.setIsLoading(false));
  });

  yield takeLatest(actionTypes.UpdateScoresManually, function* updateScoresManuallySaga(action) {
    yield put(actions.setIsLoading(true));
    // Set new list of questions without the selected question to delete it
    yield updateContent({
      guid: action.payload.content_guid,
      name: action.payload.name,
      description: action.payload.description || '',
      status: 'active',
      is_public: 1,
      test_was_touched: action.payload.test_was_touched ? 1 : 0,
      data: {
        ...action.payload.data,
        was_touched: action.payload.test_was_touched ? 1 : 0,
        time: action.payload.duration || (action.payload.data && action.payload.data.time) || 0,
        questions: action.payload.data.questions
          .filter((question) => question !== undefined)
          .map((question) => ({ guid: question.question_guid, ranking_scale: question.ranking_scale })),
      },
    });
    // Get updated test with new questions to make sure that local displays remote correctly
    const { data } = yield getTestQuestions({ guid: action.payload.content_guid });
    // Update Redux
    if (data && data.status === 'success' && data.data) {
      yield put(
        actions.setTest({
          ...action.payload,
        })
      );
      yield put(actions.setQuestions(data.data.data.questions));
      yield put(actions.setScores(data.data.data.questions.map((question) => ({ questionGuid: question.question_guid, score: question.ranking_scale }))));
    }
    yield put(actions.setIsLoading(false));
  });

  yield takeLatest(actionTypes.UpdateTest, function* updateTestSaga(action) {
    yield put(actions.setIsLoading(true));
    const shouldBeForAll = action.payload.test.students.length === 0 || action.payload.test.students.length === action.payload.test.allStudents.length;
    action.payload.test.item_for = shouldBeForAll ? 'all' : 'some-users';

    let contentGuid = action.payload.test.content_guid;

    // Check for privileges of current user to update or create new content
    if (action.payload.test.author_guid === action.payload.test.currentUserGuid) {
      // Update current content
      yield updateContent({
        guid: action.payload.test.content_guid,
        name: action.payload.test.name,
        description: action.payload.test.description || '',
        is_public: 1,
        is_evaluable: action.payload.test.taskType === 'evaluative' ? 1 : 0,
        can_deliver_late: action.payload.test.can_deliver_late ? 1 : 0,
        ranking_scale: 10,
        ...(action.payload.test.dueDate ? { deliver_at: action.payload.test.dueDate.toISOString().slice(0, 19).replace('T', ' ') } : {}),
        data: {
          ...action.payload.test.data,
          time: action.payload.test.duration || (action.payload.test.data && action.payload.test.data.time) || 0,
          questions: action.payload.questions
            .filter((question) => question !== undefined)
            .map((question) => ({ guid: question.question_guid, ranking_scale: question.ranking_scale })),
        },
      });
    } else {
      // post new content owned by the user.
      let newContentResponse = yield postNewContent({
        description: action.payload.test.description || '',
        name: action.payload.test.name,
        status: 'active',
        is_public: 1,
        is_teacher_only: 0,
        mobile_friendly: 1,
        is_evaluable: action.payload.test.taskType === 'evaluative' ? 1 : 0,
        item_for: shouldBeForAll ? [] : action.payload.test.students.map((student) => student.person_guid),
        disciplines: [],
        educationLevels: [],
        educationYears: [],
        ranking_scale: 10,
        type_guid: 'CTTY_09',
        data: {
          ...action.payload.test.data,
          time: action.payload.test.duration || (action.payload.test.data && action.payload.test.data.time) || 0,
          questions: action.payload.questions
            .filter((question) => question !== undefined)
            .map((question) => ({ guid: question.question_guid, ranking_scale: question.ranking_scale })),
        },
      });

      // update content guid to reference
      contentGuid = newContentResponse.data.data.guid;
    }

    // Update lesson
    yield putSectionOfLesson({
      guid: action.payload.test.guid,
      data: {
        content_guid: contentGuid, // This is where the content is referenced.
        deliver_at: action.payload.test.dueDate ? action.payload.test.dueDate.toISOString().slice(0, 19).replace('T', ' ') : null, // Check for dueDate. Cannot send null.
        description: action.payload.test.description || '',
        educationLevels: [],
        educationYears: [],
        item_for: shouldBeForAll ? 'all' : 'some-users',
        item_for_users: shouldBeForAll ? [] : action.payload.test.students.map((student) => student.person_guid),
        lesson_guid: action.payload.test.lessonGuid,
        name: action.payload.test.name,
        section: action.payload.test.section.name,
        parent_guid: action.payload.test.section.id,
        status: action.payload.test.status,
        published_at: action.payload.test.published_at,
        can_deliver_late: action.payload.test.can_deliver_late ? 1 : 0,
        learningObjectives: (action.payload.test.learningObjectives || []).map((item) => (isString(item) ? item : item.guid)),
        //teacher_notes: action.payload.test.teacherNotes,
      },
    });

    // Get updated test with new questions to make sure that local displays remote correctly
    // const { data } = yield getTestQuestions({ guid: action.payload.content_guid });
    // Update Redux
    // if (data && data.status === 'success' && data.data) {
    yield put(actions.setTest({ ...action.payload.test, content_guid: contentGuid, author_guid: action.payload.test.currentUserGuid }));
    // yield put(actions.setQuestions(data.data.data.questions));
    // yield put(actions.setScores(data.data.data.questions.map((question) => ({ questionGuid: question.question_guid, score: question.ranking_scale }))));
    // }
    yield put(actions.setIsLoading(false));
  });

  yield takeLatest(actionTypes.ReplaceQuestion, function* replaceQuestionSaga(action) {
    yield put(actions.setIsLoading(true));
    // Given that the teacher is attempting to edit a question that means questions array exists for sure.
    let tempQuestions = action.payload.testContent.data.questions;
    // Get ranking scale of question
    let currentQuestion = tempQuestions.filter((question) => question.question_guid === action.payload.oldQuestionContentGuid)[0];

    // Create new question content
    let newQuestionAsContent = yield postNewContent({
      name: action.payload.newQuestion.title || '', // start with empty question name
      description: action.payload.newQuestion.description || '', // start with empty question description
      type_guid: 'CTTY_14',
      is_teacher_only: 0,
      data: action.payload.newQuestion.data, // current data of lemonade
      question_type_guid: currentQuestion.question_type_guid,
      is_autoevaluative: currentQuestion.is_autoevaluative,
      is_public: 1,
    });
    const indexOfQuestion = tempQuestions.map((i) => i.question_guid).indexOf(action.payload.oldQuestionContentGuid);
    // Remove old question from questions array and replace with new one
    tempQuestions.splice(indexOfQuestion, 1, {
      question_guid: newQuestionAsContent.data.data.guid,
      is_autoevaluative: newQuestionAsContent.data.data.isEvaluable ? 1 : 0,
      ranking_scale: currentQuestion.ranking_scale,
    });
    // Conform to request array of objects
    tempQuestions = tempQuestions.map((question) => ({
      guid: question.question_guid,
      is_autoevaluative: question.is_autoevaluative,
      ranking_scale: question.ranking_scale,
    }));
    // Save content to question
    yield updateContent({
      guid: action.payload.currentTestGuid,
      data: {
        ...action.payload.testContent.data,
        time: action.payload.testContent.test_time,
        questions: tempQuestions,
      },
    });
    // get updated test with new questions to make sure that local displays remote correctly
    const { data } = yield getTestQuestions({ guid: action.payload.currentTestGuid });
    //   Update Redux
    if (data && data.status === 'success' && data.data) {
      yield put(actions.setQuestions(data.data.data.questions));
      yield put(actions.setScores(data.data.data.questions.map((question) => ({ questionGuid: question.question_guid, score: question.ranking_scale }))));
    }
    yield put(actions.setIsLoading(false));
  });
}
