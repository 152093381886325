import * as coreEntities from '_core/store/index';
import { isTeams } from '_core/utils/teamsTab';
import { TERMS } from 'app/config/config';
import LayoutConfig from 'app/config/layout/LayoutConfig';
import DialogLoginStage from 'app/modules/components/dialogs/DialogLoginStage/DialogLoginStage';
import LandingEdelvivesPlus from 'app/modules/compositions/LandingEdelvivesPlus/LandingEdelvivesPlus';
import AuthView from 'app/modules/views/AuthView/AuthView';
import React from 'react';
import { useSelector } from 'react-redux';
import MSTeamsTab from '../../MSTeamsTab';
import useLoginPage from './useLoginPage';

const LoginPage = () => {
  const { showStageDialog, onSelectStage } = useLoginPage();
  const language = useSelector((state) => coreEntities.i18n.selectors.getLanguage(state));
  return (
    <>
      {isTeams() && <MSTeamsTab />}
      {!isTeams() && (
        <>
          <DialogLoginStage open={showStageDialog} onClick={onSelectStage} />
          <AuthView form="login" showSignup={LayoutConfig.login.showSignup} right={<LandingEdelvivesPlus />} legal={TERMS} privacy={TERMS} />
        </>
      )}
    </>
  );
};

export default LoginPage;
