import React from 'react';
import AppLogo1 from '../assets/app1.png';
import AppLogo2 from '../assets/app2.png';
import AppLogo3 from '../assets/app3.png';
import AppLogo4 from '../assets/app4.png';
import PopCorn from '../assets/palomitas 1.png';
import PolpLogo from '../assets/polp.png';

const PopupLarge = ({ t, onRegister }) => {
  return (
    <div className="popup-large">
      <div className="popup-large__content">
        <div className="popup-large__image">
          <img src={PopCorn} alt="popcorn" />
        </div>
        <div className="popup-large__logo">
          <img src={PolpLogo} alt="polp-logo" />
        </div>
        <div className="popup-large__register">
          <p>{t('polp:Polp title')}</p>
          <button onClick={onRegister}>{t('polp:Polp register')}</button>
        </div>
        <div className="popup-large__footer">
          <p>{t('polp:Polp footer')}</p>
          <div className="popup-large__apps">
            <img src={AppLogo1} alt="" />
            <img src={AppLogo2} alt="" />
            <img src={AppLogo3} alt="" />
            <img src={AppLogo4} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

PopupLarge.defaultProps = {
  onRegister: () => {},
};

export default PopupLarge;
