import Button from '_core/modules/atoms/Button';
import DialogDefault from '_core/modules/components/dialogs/components/Dialog';
import { default as React } from 'react';
import useDialogLoginStage from './useDialogLoginStage';

const DialogLoginStage = ({ open, onClick, onSuccess }) => {
  const { t, stages } = useDialogLoginStage({ onSuccess });

  return (
    <DialogDefault title={t('login:Selecciona una etapa para continuar')} size="s" open={open} className={'dialog-login-stage'}>
      <div className="dialog-login-stage__list">
        {stages.map((stage, index) => (
          <Button
            key={stage.guid}
            size="small"
            onClick={() => onClick(stage)}
            color="transparent-primary"
            text={stage.name}
            icon="chevron-right"
            iconPosition="right"
            iconColor="primary"
          />
        ))}
      </div>
    </DialogDefault>
  );
};

DialogLoginStage.defaultProps = {
  open: false,
  onClick: function () {},
};

export default DialogLoginStage;
