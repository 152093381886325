import * as coreEntities from '_core/store/index';
import { getRoleUserString } from '_core/utils/user';
import { DEFAULT_LANG } from 'app/config/config';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const InitsContainer = () => {
  const currentUser = useSelector((state) => coreEntities.auth.selectors.getUser(state));

  useEffect(() => {
    if (window.StonlyWidget !== undefined) {
      window.StonlyWidget('enableDebug');
      if (currentUser?.guid) {
        const data = {
          'user-segment': currentUser.is_demo === 1 ? 'demo' : '',
          lang: currentUser.lang_id || DEFAULT_LANG,
          role: getRoleUserString(currentUser),
        };
        window.StonlyWidget('identify', currentUser.guid, data);
      } else {
        window.StonlyWidget('identify', null, { 'user-segment': '' });
      }
    }
  }, [currentUser]);

  return <></>;
};

export default InitsContainer;
