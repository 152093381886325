import { createDemoUser } from '_core/crud/demo.crud';
import { useRouter } from '_core/hooks/useRouter';
import { useTranslate } from '_core/hooks/useTranslate';
import LoadingDots from '_core/modules/atoms/LoadingDots';
import * as coreEntities from '_core/store/index';
import Logo from 'app/assets/img/logo.png';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const DemoLoading = () => {
  const { t } = useTranslate();
  const payload = useSelector((state) => coreEntities.demo.selectors.getPayload(state));
  const dispatch = useDispatch();
  const history = useRouter();

  useEffect(() => {
    if (Object.entries(payload).length > 0) {
      openDemoExperience(payload);
    }
  }, [payload]);

  async function openDemoExperience(payload) {
    const response = await createDemoUser(payload);
    if (response.status == 200) {
      const { data } = response.data;
      const { teacher, students } = data;
      const teacherToken = teacher.session;
      const studentToken = students[0].session;

      //saving student token in redux:
      dispatch(coreEntities.demo.actions.setDemoStudent(studentToken));
      //log in with teacher token
      history.push('/auth/login/' + teacherToken);
      dispatch(coreEntities.demo.actions.setPayload({}));
    }
  }

  return <LoadingDots loadingPic={Logo} loadingText={t('demo:Estamos preparando tu entorno Demo')} />;
};

export default DemoLoading;
