import Image from '../../../_core/variables/images';
import DefaultUser from '../../assets/img/default/default-user.png';
import NoPictureProgram from '../../assets/img/edelvives-e.png';
import { ReactComponent as BarMenuLogo } from '../../assets/img/logo/bar-menu-logo.svg';
import LogoBig from '../../assets/img/logo/logo-big.png';
import LogoIconInverse from '../../assets/img/logo/logo-icon-inverse.png';
import LogoIconS from '../../assets/img/logo/logo-icon-s.png';
import LogoWhite from '../../assets/img/logo/logo-white.png';
import NoPosts from '../../assets/img/megaphone.png';
import Signup1 from '../../assets/img/signup/img01.png';

const getCoreImage = Image.getImage;

Image.getImage = (id) => {
  let componentImage;
  switch (id) {
    case 'logoBig':
      componentImage = LogoBig;
      break;

    case 'logoIconS':
      componentImage = LogoIconS;
      break;

    case 'defaultUser':
      componentImage = DefaultUser;
      break;

    case 'barMenuLogo':
      componentImage = BarMenuLogo;
      break;

    case 'logoIconInverse':
      componentImage = LogoIconInverse;
      break;

    case 'login':
      componentImage = Signup1;
      break;

    case 'logoWhite':
      componentImage = LogoWhite;
      break;

    case 'noPosts':
      componentImage = NoPosts;
      break;

    case 'noPictureProgram':
      componentImage = NoPictureProgram;
      break;

    default:
      componentImage = getCoreImage(id);
      break;
  }

  return componentImage;
};
