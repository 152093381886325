import { loginOauth, loginPublisher, loginSSO } from '_core/crud/auth.crud';
import { useTranslate } from '_core/hooks/useTranslate';
import { onClickSignup, saveGlobalSession } from '_core/modules/components/ssoOraculo/serviceOraculo';
import * as coreEntities from '_core/store/index';
import { polpApiUrl, polpAppID } from 'app/config/config';
import { useCallback, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

export function useAuthView() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { t } = useTranslate();

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    errorMsg: '',
    loading: false,
  });
  const [authData, setAuthData] = useState({});

  const enableLoading = () => {
    setState({ loading: true });
  };

  const disableLoading = () => {
    setState({ loading: false });
  };

  // Tipo de login: user/pass
  const submitLogin = useCallback(
    (email, pass) => {
      enableLoading();
      setTimeout(async () => {
        //1. Se comprueba si el usuario existe en POLP
        fetch(polpApiUrl[window.origin] + '/sessions', {
          method: 'POST',
          body: JSON.stringify({ user: email, pass: pass, refresh: false }),
          headers: { fid: polpAppID[window.origin], 'Content-Type': 'application/json' },
        })
          .then((response) => response.json())
          .then((data) => {
            //2. Si existe, hacemos login en Polp con su token id y guardamos sesión global
            if (data.token_id) {
              onSubmitOraculo(data.token_id);
              dispatch(coreEntities.auth.actions.loginOraculo(data.token_id));
              saveGlobalSession(data.sid);
            }
            //3. Si no existe, se pasa a login con Edelvives
            if (data.error === 'user | pass') {
              loginWithEdelvives(email, pass);
            }
          })
          .catch((error) => {
            showError(error);
          });
      }, 1000);
    },
    [showError]
  );

  const loginWithEdelvives = useCallback(
    (email, pass) => {
      setTimeout(() => {
        enableLoading();
        loginPublisher(email, pass)
          .then((data) => {
            if (data?.data?.data?.token) {
              disableLoading();
              dispatch(coreEntities.auth.actions.login(data.data.data));
            } else {
              showError(data);
            }
          })
          .catch((error) => {
            showError(error);
          });
      }, 1000);
    },
    [showError]
  );

  //Tipo de login: SSO en Edelvives
  const onSSO = useCallback(
    (token, identityProvider) => {
      let identity_provider = identityProvider || 'oraculo';
      enableLoading();
      setTimeout(() => {
        fetch(polpApiUrl[window.origin] + '/sessions', {
          method: 'POST',
          body: JSON.stringify({ token: token, identity_provider: identity_provider }),
          headers: { fid: polpAppID[window.origin], 'Content-Type': 'application/json' },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.token_id) {
              //Si se obtiene token_id de Oraculo, hacemos login en Polp con su token id y guardamos sesión global
              //no mandamos el identity provider ya que el provider en este caso es Oraculo
              dispatch(coreEntities.auth.actions.loginOraculo(data.token_id));
              saveGlobalSession(data.sid);
              onSubmitOraculo(data.token_id);
            } else {
              //En caso de no obtener token_id de Oraculo, hacemos SSO con el provider google/microsoft directamente
              loginSSO(token, identity_provider)
                .then((data) => {
                  if (data.data.data.token) {
                    disableLoading();
                    dispatch(coreEntities.auth.actions.login(data.data.data));
                  } else {
                    showError(data);
                  }
                })
                .catch((error) => {
                  showError(error);
                });
            }
          });
      }, 1000);
    },
    [showError]
  );

  const onSSOForMSTeams = useCallback(
    (token, identity_provider) => {
      enableLoading();
      setTimeout(() => {
        loginSSO(token, identity_provider)
          .then((data) => {
            if (data.data.data.token) {
              setAuthData(data.data.data);
              disableLoading();
            } else {
              showError(data);
            }
          })
          .catch((error) => {
            showError(error);
          });
      }, 1000);
    },
    [showError]
  );

  function onSubmitOraculo(ssoToken, provider) {
    const identityProvider = provider || 'oraculo';

    loginOauth(ssoToken, identityProvider)
      .then((data) => {
        if (data.data.data.token) {
          disableLoading();
          dispatch(coreEntities.auth.actions.login(data.data.data));
        } else {
          showError(data);
        }
      })
      .catch((error) => {
        loginSSO(ssoToken, provider)
          .then((data) => {
            if (data.data.data.token) {
              disableLoading();
              dispatch(coreEntities.auth.actions.login(data.data.data));
            } else {
              showError(data);
            }
          })
          .catch((error) => {
            showError(error);
          });
      });
  }

  function showError(error) {
    const errorMsg = error?.error?.data ? t(error.error.data.error.errorKey) : t('login:Invalid login');
    disableLoading();
    setState({ loading: false });
    addToast(t(`login:${errorMsg}`), {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  function onRegister() {
    onClickSignup();
  }

  return { form: 'login', loading: state.loading, submitLogin, onSSO, onSSOForMSTeams, onRegister, authData };
}
