import i18next from 'i18next';

export const initLayoutConfig = {
  name: 'EDELVIVES_DIGITAL_PLUS',
  programPicture: true,
  login: {
    showSignup: false,
    showOraculo: false,
  },
  home: {
    headerAnimation: false, //The header animates to sticky when scroll down
    tabs: [
      //The number of tabs its going to show in the home
      {
        translateId: 'Classrooms',
        id: 'classrooms',
        disabled: false,
      },
      {
        translateId: 'Library',
        id: 'library',
        disabled: false,
      },
      /*{
        translateId: 'Resources',
        id: 'resources',
        disabled: true,
      },*/
    ],
    tabActive: 'classrooms', //The default active tab
    showLogo: false, //Show the logo in the top of home
    showHello: false, //Show hello and the name in the top of home
    onlyNewClass: false, //Show only the option to create new class (if false it shows a dropdown with other options)
    showTeachersInClassrooms: true, //Show the teachers joined to each classroom in the classroom card
  },
  createClassroom: {
    headerShowLogo: true, //Show the logo in the top
    headerShowStepsInCreate: true, //Show the steps to create a class
    lessonItemStatus: 'published',
  },
  profile: {
    showSchool: false, //Show the school info in the first section of the profile
    showPreferences: true, //Show the section Preferences
  },
  course: {
    stream: {
      showBooks: false, //Show the books container at the right of the page
      showTasks: false, //Show the tasks container at the right of the page
      showEvents: false, //Show the events container at the right of the page
      background: true, //Show custom background in stream. By default if true, show a default bacground stored locally
    },
  },
};

// así nos las devuelve con la traduccion
export function getInitLayoutTabs() {
  let tabs = [];
  if (initLayoutConfig.home && initLayoutConfig.home.tabs) {
    tabs = initLayoutConfig.home.tabs.map((item) => ({ ...item, name: i18next.t(`my-courses:${item.translateId}`) }));
  }

  return tabs;
}

export default initLayoutConfig;
