import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '_core/hooks/useTranslate';
import TextHtml from '_core/modules/atoms/TextHtml';
import DialogDefault from '_core/modules/components/dialogs/components/Dialog';
import * as coreEntities from '_core/store/index';

const DialogInfo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const infoDialog = useSelector((state) => coreEntities.ui.selectors.getInfoDialog(state));

  useEffect(() => {
    if (infoDialog && infoDialog.text) {
      setText(infoDialog.text);
      setIsOpen(true);
    }
  }, [infoDialog.text]);

  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');

  function closeDialog() {
    dispatch(coreEntities.ui.actions.setShowInfoDialog({ text: null }));
    setIsOpen(false);
  }

  return (
    <DialogDefault title=" " size="small" open={isOpen} className={'dialog-info'} onClose={closeDialog}>
      <div className="dialog-info__container">
        <TextHtml text={text} className="dialog-info__text" />
      </div>
    </DialogDefault>
  );
};

export default DialogInfo;
