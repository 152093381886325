import { useTranslate } from '_core/hooks/useTranslate';
import TextHtml from '_core/modules/atoms/TextHtml';
import DialogDefault from '_core/modules/components/dialogs/components/Dialog';
import * as coreEntities from '_core/store/index';
import { PRIVACY, TERMS } from 'app/config/config';
import { defaultConfig } from 'app/config/environment';
import LandingEdelvivesPlus from 'app/modules/compositions/LandingEdelvivesPlus/LandingEdelvivesPlus';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const POLITICS = { LEGAL: 'legal', PRIVACY: 'privacy' };
const AboutView = () => {
  const { t } = useTranslate();
  const history = useHistory();
  const language = useSelector((state) => coreEntities.i18n.selectors.getLanguage(state));

  const [showPolitics, setShowPolitics] = useState(null);

  let lang = language && Object.keys(PRIVACY).includes(language) ? language : 'es';

  function goToLogin() {
    history.push(`/auth/login`);
  }

  function onShowPolitics(selection) {
    const politics = selection === POLITICS.PRIVACY ? PRIVACY[lang] : TERMS[lang];
    if (politics?.endsWith('pdf')) {
      if (selection === POLITICS.PRIVACY) window.open(defaultConfig.BASE_URL_ + 'privacy', '_blank');
      if (selection === POLITICS.LEGAL) window.open(defaultConfig.BASE_URL_ + 'terms', '_blank');
    } else {
      setShowPolitics(selection);
    }
  }

  return (
    <div className="about-view">
      <div className="about-view__wrapper">
        <LandingEdelvivesPlus />
        <div className="about-view__login" onClick={() => goToLogin()}>
          <span>{t('login:Log in')}</span>
        </div>
      </div>
      <div className="login-form__politics">
        <div className="login-form__legal-advice" onClick={() => onShowPolitics(POLITICS.LEGAL)}>
          {t('login:Legal advice')}
        </div>
        <div className="login-form__privacy" onClick={() => onShowPolitics(POLITICS.PRIVACY)}>
          {t('login:Privacy')}
        </div>
      </div>
      <DialogDefault
        size="xl"
        className="dialog-terms"
        title={showPolitics === POLITICS.PRIVACY ? t('login:Privacy').toUpperCase() : t('login:Legal advice').toUpperCase()}
        open={showPolitics}
        onClose={() => setShowPolitics(null)}
      >
        <TextHtml text={showPolitics === POLITICS.PRIVACY ? PRIVACY : TERMS} />
      </DialogDefault>
    </div>
  );
};

export default AboutView;
