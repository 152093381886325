import { useState } from 'react';

export default function useInputPassword() {
  const [visible, setVisible] = useState(false);

  function onVisible() {
    setVisible(!visible);
  }
  return { visible, onVisible };
}
