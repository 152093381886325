import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const Item = ({ item, i, guidUrl }) => {
  const [active, setActive] = useState(null);

  useEffect(() => {
    if (guidUrl && item && guidUrl === item.props.liActive) {
      setTimeout(() => {
        setActive(true);
      }, 1000);

      setTimeout(() => {
        setActive(false);
      }, 3000);
    }
  }, [guidUrl]);

  return (
    <li key={i} className={`basic-list__item ${active ? 'basic-list__item--active' : ''}`}>
      {item}
    </li>
  );
};

const BasicList = ({ items, guidUrl, withBorder }) => {
  useEffect(() => {
    if (guidUrl) {
      const element = document.getElementById(guidUrl);
      setTimeout(() => {
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 1000);
    }
  }, [guidUrl]);

  return (
    <ul className={`basic-list ${withBorder ? 'basic-list--with-border' : ''}`}>
      {items.map((item, i) => {
        return <Item key={i} item={item} i={i} guidUrl={guidUrl} />;
      })}
    </ul>
  );
};

BasicList.propTypes = {
  items: PropTypes.array,
};

BasicList.defaultProps = {
  items: [],
};

export default BasicList;
