import { ValidateEmail } from '_core';
import Hr from '_core/modules/atoms/Hr';
import InputText from '_core/modules/atoms/InputText';
import Logo from '_core/modules/atoms/Logo/Logo';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

const SignUpStepAuthorization = ({ t, onChangeDisabledButton, changeAuthorization, authorization, changeUser, user, layoutRight, step }) => {
  const [showParentConsent, setShowParentConsent] = useState(false);
  const [birthdayError, setBirthdayError] = useState(false);

  useEffect(() => {
    onChangeDisabledButton(true);
  }, []);

  useEffect(() => {
    onChangeDisabledButton(!validate());
  }, [authorization]);

  function validate() {
    let years = moment().diff(user.birthdate, 'years', true);
    return authorization && (!authorization.need || (authorization.need && ValidateEmail(authorization.parentEmail))) && years < 100;
  }

  function onChange(e) {
    changeAuthorization({ ...authorization, parentEmail: e.target.value });
  }

  function onChangeUser(e) {
    if (
      new Date(e.target.value) &&
      moment().diff(new Date(e.target.value), 'years', true) >= 3 &&
      moment().diff(new Date(e.target.value), 'years', true) < 80
    ) {
      setBirthdayError(false);
      changeUser({ ...user, birthdate: e.target.value });
    } else {
      setBirthdayError(t('Please enter a valid birthday'));
      setShowParentConsent(false);
      changeUser({ ...user, birthdate: undefined });
    }
  }

  useEffect(() => {
    if (user.birthdate) {
      let years = moment().diff(user.birthdate, 'years', true);
      if (years < 14) {
        setShowParentConsent(true);
        changeAuthorization({ ...authorization, need: true });
      } else {
        setShowParentConsent(false);
        changeAuthorization({ ...authorization, need: false });
      }
    } else {
      onChangeDisabledButton(true);
    }
  }, [user.birthdate]);

  return (
    <div className="signup-step-license signup-step-license--authorization">
      <div className="signup__step">
        <div className="signup__column1">
          <div className="signup__step-content">
            <div className="signup__logo">
              <Logo size="big" />
            </div>
            <div className="signup__header">
              <span className="signup__titlepart2">{t('Authorization')}&nbsp;</span>
            </div>
            <span className="signup__description">{t('Due to data protection law we need to know your date of birth')}</span>

            <div className="signup__content">
              <div className="signup__line-with-text signup__item">
                <InputText
                  error={birthdayError}
                  placeholder={t('profile:Name')}
                  type="date"
                  onChange={onChangeUser}
                  icon="cake"
                  iconLeft={true}
                  max={`${new Date().getFullYear() - 3}-${new Date().getMonth() + 1}-${new Date().getDate()}`}
                  // value={user.birthdate}
                />
              </div>
            </div>
            {showParentConsent && (
              <>
                <div className="signup__item">
                  <Hr />
                </div>
                <div className="signup__header signup__item">
                  <span className="signup__titlepart1">
                    {t('Parent or')}&nbsp;<span className="signup__titlepart2">{t('legal guardian')}</span>
                  </span>
                </div>

                <div className="signup__item">
                  <div className="signup__item">
                    <InputText placeholder="Email" icon="email" iconLeft={true} onChange={onChange} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="signup__column2">
          {/* signup4 */}
          {layoutRight || <div className={clsx('signup__image', `signup__image--step${step}`)}></div>}
        </div>
      </div>
    </div>
  );
};

SignUpStepAuthorization.defaultProps = {
  step: 3,
};

export default withTranslation(['signup', 'profile', 'placeholder'])(SignUpStepAuthorization);
