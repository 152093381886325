import React from 'react';
import Icon from '_core/modules/atoms/Icon';
import PolpLogo from '../assets/polp.png';

const PopupSmall = ({ t, onRegister, onClose }) => {
  return (
    <div className="popup-small">
      <div className="popup-small__content">
        <div className="popup-small__close-btn" onClick={onClose}>
          <Icon type="close" />
        </div>
        <div className="popup-small__logo">
          <img src={PolpLogo} alt="polp-logo" />
        </div>
        <div className="popup-small__register">
          <p>{t('polp:Polp title')}</p>
          <button onClick={onRegister}>{t('polp:Polp register')}</button>
        </div>
        <div className="popup-small__footer">
          <p>{t('polp:Polp small footer')}</p>
        </div>
      </div>
    </div>
  );
};

PopupSmall.defaultProps = {
  onRegister: () => {},
  onClose: () => {},
};

export default PopupSmall;
