// import MSTeamsTab from 'app/pages/MSTeamsTab';
import LoginForm from 'app/modules/compositions/LoginForm';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import LayoutLogin from '_core/layout/LayoutLogin';
import RecoverForm from '_core/modules/components/session/RecoverPassword';
import ResetForm from '_core/modules/components/session/ResetPassword';
import SignupForm from '_core/modules/components/session/SignupForm';
import { useAuthView } from './useAuthView';

const AuthView = ({ form, showSignup, right, legal, privacy }) => {
  const { loading, submitLogin, onSSO, onRegister } = useAuthView();
  return (
    <LayoutLogin>
      <div className="auth-view__form">
        {form === 'login' && (
          <LoginForm
            loading={loading}
            showRememberMe={false}
            showForgotPassword={true}
            showSignup={showSignup}
            onSubmit={submitLogin}
            onSSO={onSSO}
            onRegister={onRegister}
            legal={legal}
            privacy={privacy}
          />
        )}
        {form === 'signup' && <SignupForm />}
        {form === 'recover' && <RecoverForm />}
        {form === 'reset' && <ResetForm />}
      </div>
      {/* </div> */}
      <div className="auth-view__image">{right || <div className="login-image" />}</div>
    </LayoutLogin>
  );
};

AuthView.propTypes = {
  form: PropTypes.string,
  showSignup: PropTypes.bool,
};

AuthView.defaultProps = {
  form: PropTypes.oneOf(['login', 'signup', 'recover', 'reset']),
  showSignup: true,
};

export default withTranslation('ui')(AuthView);
