import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslate } from '_core/hooks/useTranslate';

import Button from '_core/modules/atoms/Button';
import LineWithText from '_core/modules/atoms/LineWithText';
import SelectCustomizable from '_core/modules/atoms/SelectCustomizable';
import Tag from '_core/modules/atoms/Tag';
import BookCard from '_core/modules/components/cards/BookCard';
import MosaicList from '_core/modules/components/lists/components/MosaicList';
import { isEmptyObj } from '_core/utils/validation';

const AddClassStep = ({ mode, books, courses, createClassAndCourse, selectedCourse, setSelectedCourse, addCourseToChannel }) => {
  const { t } = useTranslate();

  const [selectedBook, setSelectedBook] = useState({});
  const [showFinalizeStep, setShowFinalizeStep] = useState(false);

  useEffect(() => {
    if (selectedBook && !isEmptyObj(selectedBook)) {
      setSelectedCourse('');
    }
  }, [selectedBook]);

  useEffect(() => {
    if (selectedCourse) {
      setSelectedBook({});
    }
  }, [selectedCourse]);

  return (
    <div style={{ marginTop: 16, marginBottom: 16, textAlign: 'center' }}>
      {!showFinalizeStep && (
        <>
          {courses.length > 0 && books.length > 0 && (
            <>
              {mode !== 'register' && (
                <>
                  <div className="signup-step__teams-select-class">{t('signup:Select a course for this channel')}</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 2 }}>
                      <SelectCustomizable
                        style={{ height: '100%', marginRight: 16 }}
                        options={courses.map((course) => ({
                          label: course.name,
                          key: course.guid,
                        }))}
                        placeholder={t('signup:Choose the class you want to assign')}
                        onChange={(e) => {
                          setSelectedCourse(e.key);
                        }}
                      />
                    </div>
                    <div style={{ flex: 1, marginLeft: 16 }}>
                      <Button
                        text={t('signup:Add this course to channel')}
                        disabled={!Boolean(selectedCourse)}
                        onClick={() => {
                          addCourseToChannel(selectedBook);
                          setShowFinalizeStep(true);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {courses.length > 0 && books.length > 0 && (
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <LineWithText text="O" />
            </div>
          )}

          {books.length > 0 && (
            <>
              <div className="signup-step__teams-select-class-to-create">{t('signup:Create course for channel')}</div>
              <MosaicList
                columns={2}
                items={books.map((book) => (
                  <BookCard
                    {...book}
                    subtext={
                      <div>
                        <span className="new-classroom__year">{t(`education-years:${book.education_year?.guid}`)}</span>{' '}
                        <span className="new-classroom__level">{t(`education-levels:${book.education_level?.guid}`)}</span>
                      </div>
                    }
                    customRight={<Tag text="Proximamente" />}
                    text={book.courseName}
                    onChangeSelected={() => {
                      setSelectedBook(book);
                    }}
                    view={'selectable'}
                    type=""
                    selected={book.guid === selectedBook.guid}
                    useWindow={false}
                    getScrollParent={() => {}}
                    configProgramPicture={true}
                    disabledTitle={null}
                  />
                ))}
              />
              <Button
                text={t('signup:Create course for channel')}
                disabled={!Boolean(selectedBook.guid)}
                onClick={() => {
                  createClassAndCourse(selectedBook);
                  setShowFinalizeStep(true);
                }}
              />
            </>
          )}
        </>
      )}

      {showFinalizeStep && <div className="signup-step__teams-add-class">{t('signup:Click on the below save button to finalize the process')}</div>}
    </div>
  );
};

AddClassStep.propTypes = {
  courses: PropTypes.array,
};

AddClassStep.defaultProps = {
  courses: [],
};

export default AddClassStep;
