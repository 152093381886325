import React, { useState } from 'react';
import { useTranslate } from '_core/hooks/useTranslate';
import Image from '_core/modules/atoms/Image';
import TextHtml from '_core/modules/atoms/TextHtml';
import DialogDefault from '_core/modules/components/dialogs/components/Dialog';

const LandingEdelvivesPlus = () => {
  const { t } = useTranslate();

  const [showKnowMore, setShowKnowMore] = useState(false);

  return (
    <div className="landing-edelvives-plus">
      <div className="landing-edelvives__wrapper">
        <div className="landing-edelvives-plus__logo">
          <Image id="logoWhite" />
        </div>
        <div className="landing-edelvives-plus__text">{t('auth:Edelvives virtual learning environment')}</div>
        <div className="landing-edelvives__know-more" onClick={() => setShowKnowMore(true)}>
          <span>{t('auth:Know more')}</span>
        </div>
      </div>
      <DialogDefault open={showKnowMore} size="small" className={'dialog-show-know-more'} title={t('auth:Know more')} onClose={() => setShowKnowMore(false)}>
        <div className="dialog-show-know-more__container">
          <TextHtml text={t('auth:Know more text')} />
        </div>
      </DialogDefault>
    </div>
  );
};

export default LandingEdelvivesPlus;
