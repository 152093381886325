const BASE_DEV = 'https://edelvivesdigital-dev.oneclicklabs.es/';
// const BASE_DEV = 'https://edelvivesdigital-qa.oneclicklabs.es/';
// const BASE_DEV = 'https://edelvivesdigitalplus.com/';
const BASE_AGNOSTIC = window.location.origin.includes('localhost') ? BASE_DEV : window.location.origin + '/';

export const defaultConfig = {
  BASE_URL_: BASE_AGNOSTIC,
  API_URL: BASE_AGNOSTIC + 'api/',
  BASE_STORAGE_URL: BASE_AGNOSTIC + 'files-storage-tools/',
};
