import Button from '_core/modules/atoms/Button';
import ClassroomForm from '_core/modules/components/forms/ClassroomForm';
import ClassroomsSimpleList from '_core/modules/components/lists/classrooms-simple/ClassroomsSimpleList';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

const SignUpCreateClassroom = ({ t, changeDisabledButton, changeTextButton, educationLevels, disciplines, onChangeClassroom, classrooms }) => {
  changeTextButton(t('actions:Continue'));

  const [info, setInfo] = useState({});
  const [buttonAddDisabled, setButtonAddDisabled] = useState(false);

  useEffect(() => {
    changeDisabledButton(true);
  }, []);

  useEffect(() => {
    // esto lo he sacado de otro componente por si te ayuda!

    if (info.name && info.name.trim() !== '' && info.educationLevel && info.educationYear) {
      setButtonAddDisabled(false);
    } else {
      setButtonAddDisabled(true);
    }
  }, [info]);

  function onChange(data) {
    // esto lo he sacado de otro componente por si te ayuda!
    let currentInfo = { ...info };
    currentInfo[data.name] = data.value;
    if (data.name === 'educationLevel') {
      currentInfo.educationYear = null;
    }
    setInfo(currentInfo);

    // esta funcion cambia el disabled del boton de continuar., cuando se cree una clase se deberia quitar el disabled
    changeDisabledButton(false);
  }

  return (
    <div className="signup-step-create-classroom">
      <div className="signup-step-create-classroom__container">
        <div className="signup__header">
          <span className="signup__titlepart1">
            {t('Registrations')}&nbsp;<span className="signup__titlepart2">{t('Classrooms')}</span>{' '}
          </span>
        </div>

        <span className="signup__description">{t('Let`s get create your first classrooms')}</span>
        <div className="signup-step-create-classroom__form">
          <ClassroomForm info={info} onChange={onChange} educationLevels={educationLevels} disciplines={disciplines} />
          <div className="signup-step-create-classroom__button-action">
            <Button disabled={buttonAddDisabled} text={t('actions:Create classroom')} variant="inline" onClick={() => onChangeClassroom(info)} />
          </div>
        </div>
      </div>
      <div className="signup-step-license__classrooms">
        <span className="signup-step-license__classrooms-length">{t('You have created x classrooms!', { count: classrooms.length })}</span>
        <ClassroomsSimpleList classrooms={classrooms} />
      </div>
    </div>
  );
};

SignUpCreateClassroom.propTypes = {
  onSaveProfile: PropTypes.func,
};

SignUpCreateClassroom.defaultProps = {
  onSaveProfile: function () {},
};

export default withTranslation(['signup', 'actions', 'common'])(SignUpCreateClassroom);
