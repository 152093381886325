import { useTranslate } from '_core/hooks/useTranslate';
import TextHtml from '_core/modules/atoms/TextHtml';
import PdfViewerFullScreen from '_core/modules/components/pdfViewers/PdfViewerFullScreen/PdfViewerFullScreen';
import * as coreEntities from '_core/store/index';
import { TERMS } from 'app/config/config';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const TermsOfUse = () => {
  const { t } = useTranslate();
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const language = useSelector((state) => coreEntities.i18n.selectors.getLanguage(state));
  let lang = language && Object.keys(TERMS).includes(language) ? language : 'es';

  useEffect(() => {
    let elements = document.getElementsByClassName('terms');
    if (elements.length > 0) {
      document.getElementsByClassName('tyc_legal')[0].innerHTML = elements[0].innerHTML;
    }
    if (TERMS[lang] && TERMS[lang].endsWith('pdf')) {
      setShowPdfViewer(true);
    } else {
      setShowPdfViewer(false);
    }
  }, []);

  return showPdfViewer ? (
    <div>
      <PdfViewerFullScreen url={TERMS[lang]} />
    </div>
  ) : (
    <div className="dialog-terms">
      <div>{t('login:Terms')}</div>
      <TextHtml text={TERMS[lang]} />
    </div>
  );
};

export default TermsOfUse;
